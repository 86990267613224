import { marketsKeys } from '../helpers';

export const filterPairs = (pairs: [string, IMarketModified[]][], showMarket: string) => {
  if (showMarket === marketsKeys.FAVORITE_MARKETS) {
    const favoritePairs = pairs
      .flatMap(([_coinKey, coinData]) =>
        coinData.flatMap(coinString => (typeof coinString === 'string' ? JSON.parse(coinString) : coinString))
      )
      .filter(coin => coin && coin.favorites)
      .map(coin => JSON.stringify(coin));

    const uniqueFavoritePairs = Array.from(new Set(favoritePairs)).map(
      (value: string) => JSON.parse(value) as IMarketModified[]
    );

    return [[marketsKeys.FAVORITE_MARKETS, uniqueFavoritePairs]];
  } else if (showMarket !== marketsKeys.ALL_MARKETS) {
    return pairs
      .filter(([coinKey, _coinData]) => coinKey.toUpperCase() === showMarket.toUpperCase())
      .map(([coinKey, coinData]) => [coinKey.toUpperCase(), coinData]);
  } else {
    const allPairs = pairs
      .flatMap(([_coinKey, coinData]) =>
        coinData.flatMap(coinString => (typeof coinString === 'string' ? JSON.parse(coinString) : coinString))
      )
      .map(coin => JSON.stringify(coin));

    const uniqueAllPairs = Array.from(new Set(allPairs)).map((value: string) => JSON.parse(value) as IMarketModified[]);

    return [[marketsKeys.ALL_MARKETS, uniqueAllPairs]];
  }
};

import { Big, type BigSource } from 'big.js';

import { t } from '@ping/helpers';
import { format, normalize } from '@ping/utils';

const ZERO = 0 as const;

/**
 * It validates a price value against a minimum and maximum value, and returns an error message if the
 * value is outside of that range.
 * @param {BigSource} min - The minimum allowed value for the price.
 * @param {BigSource} max - The maximum allowed value for the price.
 * @param {Big} value - The value parameter is a Big object representing the price value that needs to
 * be validated.
 * @param {number} scale - The `scale` parameter is a number that represents the number of decimal
 * places to be used when formatting the numbers in the error messages. It is used in conjunction with
 * the `normalize` function to ensure that the numbers are displayed with the correct number of decimal
 * places.
 */
const validatePrice = (min: BigSource, max: BigSource, value: Big, scale: number) => {
  let errorMessage: string = undefined;

  if (value.gt(max)) {
    errorMessage = t('Maximum: %s', format.number(normalize(max, scale)));
  }
  if (value.gt(ZERO) && value.lt(min)) {
    errorMessage = t('Minimum: %s', format.number(normalize(min, scale)));
  }

  return errorMessage;
};

/**
 * It validates a amount value against a minimum and maximum value, and returns an error message if the
 * value is outside of that range.
 * @param {BigSource} min - The minimum allowed value for the amount.
 * @param {BigSource} max - The maximum allowed value for the amount.
 * @param {Big} value - The value parameter is a Big object representing the amount value that needs to
 * be validated.
 * @param {number} scale - The `scale` parameter is a number that represents the number of decimal
 * places to be used when formatting the numbers in the error messages. It is used in conjunction with
 * the `normalize` function to ensure that the numbers are displayed with the correct number of decimal
 * places.
 */
const validateAmount = (min: BigSource, max: BigSource, value: Big, scale: number) => {
  let errorMessage: string = undefined;

  if (value.gt(max)) {
    const maxAmount = normalize(max, scale);
    if (isNaN(maxAmount.toNumber())) {
      errorMessage = t('Insufficient balance');
    } else {
      errorMessage = t('Insufficient balance. Maximum: %s', format.number(maxAmount));
    }
  }
  if (value.gt(ZERO) && value.lt(min)) {
    errorMessage = t('Minimum: %s', format.number(normalize(min, scale)));
  }

  return errorMessage;
};

export const validate = {
  price: validatePrice,
  amount: validateAmount,
};

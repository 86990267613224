import { scientificNumbersParser } from '@ping/helpers';
import { pressedPriceValueSelector, useOrderBookPressedPriceStore } from './orderBookPressedPrice.store';

const PRISTINE_VALUE = '' as const;

/**
 * It subscribes to changes in the selected price and invokes a callback function with the parsed price value.
 * @param callback - The `callback` parameter is a function that will be called whenever the price
 * value changes. It takes a single argument `price`, which is a string representing the new price
 * value.
 */
export const subscribeOrderBookPressedPriceValue = (callback: (price: string) => void) => {
  return useOrderBookPressedPriceStore.sliceSubscribe(
    pressedPriceValueSelector,
    price => callback(scientificNumbersParser(price) || PRISTINE_VALUE),
    { fireImmediately: true }
  );
};

import { Paging } from '@ping/api';

/**
 * It takes a lastPage object and an allPages array and returns the nextPage number (if the next page exists)
 * @param {{filters: unknown | null, paging: Paging, data: any[] | null}} lastPage - {} - The object that contains the properties of the last page
 * @param {any[]} allPages - any[] - The array of all pages
 * @returns A number
 */

export const getNextPageParam = (
  lastPage: { filters?: unknown | null; paging?: Paging; data?: any[] | null },
  allPages: any[]
) => {
  // Condition to stop API calls for a single page
  if (lastPage.paging.total > 1) {
    const nextPage = allPages.length + 1;
    return lastPage.data.length !== 0 ? nextPage : null;
  }
};

import clsx from 'clsx';
import { useContext } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import { TimerContext } from '../timer.context';

import style from './style.module.scss';

export const TimerIndicator = (props: ICustomizable) => {
  const ctx = useContext(TimerContext);

  return (
    <span className={clsx(style['timer-indicator'], props.className)}>
      <CountdownCircleTimer
        isPlaying={ctx.isPlaying}
        size={20}
        duration={ctx.duration}
        colors='#B3B8D0'
        trailColor='#30364D'
        strokeWidth={2}
        onUpdate={ctx.setRemainingTime}
        onComplete={() => ({ shouldRepeat: true, delay: 1 })}
      />
    </span>
  );
};

import { useEffect, useRef } from 'react';
import useRowVirtualizer from '@ping/uikit/Table/TableWithVirtualList/useRowVirtualizer';
import useTableData from '@ping/uikit/Table/useTableData';
import useTableColumns from '@ping/uikit/Table/useTableColumns';
import useTable from '@ping/uikit/Table/useTable';
import TableElement from '@ping/uikit/Table/TableElement';
import cssVariable from '@ping/assets/scss/theme/exportVariables.module.scss';

interface ITableWithVirtualList extends Omit<ITable, 'hasVirtualList'> {
  classNameContainer?: string;
}

export const TableWithVirtualList = ({
  data,
  columns,
  isLoading,
  classNameContainer = '',
  ...rest
}: ITableWithVirtualList) => {
  // We need a reference to the scrolling element
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const tableData = useTableData({ data, isLoading, ...rest }) ?? [];

  const tableColumns = useTableColumns({ columns, isLoading, ...rest });

  const { rows, ...useTableRest } = useTable({
    data: tableData,
    columns: tableColumns,
    ...rest,
  });

  const { virtualRows, paddingTop, paddingBottom } = useRowVirtualizer({ rows, ref: tableContainerRef });

  useEffect(() => {
    const tableWrapper = tableContainerRef.current;
    if (tableWrapper && rest.emptyView && !isLoading && tableData.length === 0) {
      const startPosition = tableWrapper.getBoundingClientRect().top;
      tableWrapper.style.height = `${window.innerHeight - Number(cssVariable.HeaderHeight) - startPosition}px`;
    }
  }, [isLoading]);

  return (
    <div
      {...(!classNameContainer
        ? {
            style: {
              height: classNameContainer
                ? classNameContainer
                : window.innerHeight - tableContainerRef?.current?.getBoundingClientRect().top - 32,
              overflow: 'auto',
            },
          }
        : {})}
      className={classNameContainer}
      ref={tableContainerRef}
    >
      <TableElement
        data={tableData}
        tableRows={rows}
        rows={virtualRows}
        isLoading={isLoading}
        hasVirtualList
        VirtualListPaddingTop={<VirtualListPadding padding={paddingTop} />}
        VirtualListPaddingBottom={<VirtualListPadding padding={paddingBottom} />}
        {...useTableRest}
        {...rest}
      />
    </div>
  );
};

const VirtualListPadding = ({ padding }) =>
  padding > 0 && (
    <tr>
      <td style={{ height: `${padding}px` }} />
    </tr>
  );

import { Tooltip } from '@ping/uikit/TooltipA11Y';
import { Modal } from '@ping/uikit';
import { SocketConnectivityIndicator } from '@ping/components';
import { t } from '@ping/helpers';
import { useMobile } from '@ping/hooks';
import style from './style.module.scss';
import { WEBSITE_BASE_URL } from '@ping/configs';

export const Footer = () => {
  const isMobile = useMobile();

  return (
    <footer className={style['footer']}>
      <div className={style['footer--left-side']}>
        <SocketConnectivityIndicator />
      </div>
      <div className={style['footer--right-side']}>
        {isMobile ? (
          <Modal.Root>
            <Modal.Trigger className={style['footer--links']}>{t('Regulator')}</Modal.Trigger>
            <Modal.Portal>
              <Modal.Header className={style['regulator--modal-header']}>
                <Modal.CloseButton />
              </Modal.Header>
              <Modal.Content>
                <Modal.Title className={style['regulator--modal-title']}>{t('Regulator')}</Modal.Title>
                <p className={style['regulator--modal-content']}>
                  {t(
                    'SO-FIT is a self-regulatory organization approved by the Swiss Federal Supervisory Authority financial markets (FINMA) for the supervision of financial intermediaries referred to in Article 2 paragraph 3 of the Swiss Federal Law concerning the fight against money laundering and the financing of terrorism in the financial sector (AMLA).'
                  )}
                </p>
              </Modal.Content>
            </Modal.Portal>
          </Modal.Root>
        ) : (
          <Tooltip.Root>
            <Tooltip.Trigger className={style['footer--links']}>{t('Regulator')}</Tooltip.Trigger>
            <Tooltip.Content className={style['tooltip--content']}>
              {t(
                'SO-FIT is a self-regulatory organization approved by the Swiss Federal Supervisory Authority financial markets (FINMA) for the supervision of financial intermediaries referred to in Article 2 paragraph 3 of the Swiss Federal Law concerning the fight against money laundering and the financing of terrorism in the financial sector (AMLA).'
              )}
            </Tooltip.Content>
          </Tooltip.Root>
        )}
        <a href={`${WEBSITE_BASE_URL}terms-of-service`} target='_blank' className={style['footer--links']}>
          {t('Terms')}
        </a>
        <a href={`${WEBSITE_BASE_URL}help-center`} target='_blank' className={style['footer--links']}>
          {t('Help center')}
        </a>
      </div>
    </footer>
  );
};

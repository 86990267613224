import { isNil } from '@ping/utils';
import { selectedMarketInstrumentSelector, useMarketStore } from '@ping/stores/market.store';
import { useMarketPair, usePortfolio } from '../helpers';
import { useMarketSellFormState } from './market-sell-form-state.hook';

import type { IMarketDataType } from '@ping/websockets';
import { OrderFormStructure } from '../OrderFormStructure';

const ZERO = 0 as const;

interface IMarketOrderSellFormProps {
  market: IMarketDataType;
  isDisabled?: boolean;
}

export const MarketOrderSellForm = (props: IMarketOrderSellFormProps) => {
  const instrument = useMarketStore(selectedMarketInstrumentSelector);
  const pair = useMarketPair(instrument);
  const { portfolio, isPortfolioFetched } = usePortfolio();
  const balance = isPortfolioFetched ? portfolio.get(pair.baseAsset)?.availableAmount : ZERO;

  const sellState = useMarketSellFormState({
    pair,
    balance,
    marketClosePrice: props.market?.close ?? ZERO,
  });

  return (
    <OrderFormStructure
      orderKind='market'
      orderSide='sell'
      instrument={instrument}
      state={sellState}
      isDisabled={props.isDisabled || isNil(balance) || balance === ZERO}
    />
  );
};

import { useCreateTrade } from '@ping/api';
import { createContext } from 'react';

interface IFormStructureContext {
  orderState: Omit<ReturnType<typeof useCreateTrade>, 'mutate'>;
}

export const FormStructureContext = createContext<IFormStructureContext>({
  orderState: undefined,
});

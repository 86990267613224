import { useEffect, useRef } from 'react';
import useDraggableScroll from 'use-draggable-scroll';
import useIntersection from '@ping/hooks/useIntersection';
import useIsScrolling from '@ping/hooks/useIsScrolling';
import style from './style.module.scss';

type TabsOptions = { view: React.ReactNode; value: string | number; width?: number };

export interface IScrollableTab extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange' | 'defaultValue'> {
  className?: string;
  options: TabsOptions[];
  value?: TabsOptions['value'];
  onChange?: (value: TabsOptions['value'], tab: TabsOptions) => void;
}

export const ScrollableTab = ({ options, className = '', onChange, value, ...rest }: IScrollableTab) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const selectedElement = useRef<HTMLDivElement>(null);
  const { onMouseDown } = useDraggableScroll(wrapperRef);
  const inViewportWrapper = useIntersection(wrapperRef, '0px');
  const inViewportSelectedElement = useIntersection(selectedElement, '0px');
  const isScrolling = useIsScrolling();
  const scrolledWithCoin = useRef<string>(null);

  const handleChange = (value: TabsOptions['value'], tab: TabsOptions) => {
    if (onChange) {
      onChange(value, tab);
    }
  };

  const handleScrollToSelectedElement = () => {
    if (selectedElement.current) {
      selectedElement.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      scrolledWithCoin.current = value as string;
    }
  };
  useEffect(() => {
    if (scrolledWithCoin.current !== value && inViewportWrapper && !inViewportSelectedElement && !isScrolling) {
      handleScrollToSelectedElement();
      /* just to be sure in corner case of when user start from scrollRestoration of bottom of the page and then scroll to top */
      setTimeout(handleScrollToSelectedElement, 600);
    }
  }, [value, inViewportWrapper, inViewportSelectedElement, isScrolling]);

  /**
   * @description This function is used to disable pointer events on all iframes when the user presses the mouse button
   * it will fix the issue of the iframe capturing the mouse events and keep scrolling the tab after user go back to the main window
   * more info: https://app.clickup.com/t/86bxhzrhz
   */
  const handleOnMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    document.querySelectorAll('iframe').forEach(iframe => {
      iframe.style.pointerEvents = 'none';
    });
    onMouseDown(event);
  };

  /**
   * @description This function is used to enable pointer events on all iframes when the user releases the mouse button
   */
  const handleOnMouseUp = () => {
    document.querySelectorAll('iframe').forEach(iframe => {
      iframe.style.pointerEvents = 'auto';
    });
  };

  return (
    <div
      ref={wrapperRef}
      className={`${style['scrollable-tab-wrapper']} ${className}`}
      onMouseDown={handleOnMouseDown}
      onMouseUp={handleOnMouseUp}
      {...rest}
    >
      {options.map(tab => (
        <div
          style={tab.width ? { width: `${tab.width}px` } : {}}
          className={`${style['scrollable-tab-item']} ${
            value === tab.value ? style['scrollable-tab-item-selected'] : ''
          }`}
          onClick={() => handleChange(tab.value, tab)}
          key={tab.value}
          ref={value === tab.value ? selectedElement : null}
        >
          {tab.view}
        </div>
      ))}
    </div>
  );
};

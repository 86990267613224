import { Big } from 'big.js';
import { useEffect, useReducer, useRef } from 'react';

import { log, scientificNumbersParser } from '@ping/helpers';
import { useSelectedOrderKindSignal } from '@ping/signals';
import { orderBookPressedPriceStore } from '@ping/stores/orderBookPressedPrice.store';
import { isNil } from '@ping/utils';
import { isEmpty } from 'rambdax';

import { isFormFieldsValid } from '../helpers';

import type { MarketInfo } from '@ping/api';

const ZERO = 0 as const;
const BIG_ZERO = Big(ZERO);
const PRISTINE_VALUE = '' as const;
const PLACEHOLDER = '-' as const;

export interface IOrderFormState {
  activationPrice: string;
  limitPrice: string;
  price: string;
  amount: string;
  marketClosePrice: string;

  precise: Record<
    'marketClosePrice' | 'balance' | 'activationPrice' | 'limitPrice' | 'price' | 'amount' | 'total',
    Big
  >;
  max: Record<'activationPrice' | 'limitPrice' | 'price' | 'amount', Big>;
  error: Record<'amount' | 'price' | 'limitPrice' | 'activationPrice', string>;

  isFormInvalid: boolean;
  isMaxButtonVisible: boolean;
  isMaxButtonLoading: boolean;
  isAmountSliderDisabled: boolean;
  amountSliderStep: Big;
  sliderScale: number;

  balance: {
    currency: string;
    value: string;
    equivalent: { currency: string; value: string };
    isBalanceLoading: boolean;
  };
}

/**
 * It returns an immutable initial state object for a trading widget.
 */
export const getInitialState = () => {
  return Object.freeze<IOrderFormState>({
    activationPrice: PRISTINE_VALUE,
    limitPrice: scientificNumbersParser(orderBookPressedPriceStore.getValue()) ?? PRISTINE_VALUE,
    price: scientificNumbersParser(orderBookPressedPriceStore.getValue()) ?? PRISTINE_VALUE,
    amount: PRISTINE_VALUE,
    marketClosePrice: PRISTINE_VALUE,
    precise: {
      marketClosePrice: BIG_ZERO,
      balance: BIG_ZERO,
      activationPrice: BIG_ZERO,
      limitPrice: BIG_ZERO,
      price: BIG_ZERO,
      amount: BIG_ZERO,
      total: BIG_ZERO,
    },
    max: {
      activationPrice: BIG_ZERO,
      limitPrice: BIG_ZERO,
      price: BIG_ZERO,
      amount: BIG_ZERO,
    },
    error: {
      activationPrice: undefined,
      limitPrice: undefined,
      price: undefined,
      amount: undefined,
    },
    isFormInvalid: false,
    isMaxButtonVisible: false,
    isMaxButtonLoading: false,
    isAmountSliderDisabled: false,
    amountSliderStep: BIG_ZERO,
    sliderScale: ZERO,
    balance: {
      currency: PLACEHOLDER,
      value: ZERO.toString(),
      equivalent: { currency: PLACEHOLDER, value: ZERO.toString() },
      isBalanceLoading: true,
    },
  });
};

export interface IOrderFormStateParams {
  pair: MarketInfo;
  balance: number;
  marketClosePrice: number;
}

/**
 * It returns a state object and provides methods to update its properties based on the provided parameters.
 * @param {string} debug - A string used for debugging purposes, to help identify which instance of the
 * MarketOrderSection is being referred to in the logs.
 * @param {IOrderFormStateParams} params - The `params` parameter is an object containing the
 * following properties:
 * - pair: MarketInfo
 * - balance: number
 * - marketClosePrice: number
 * @param manage - `manage` is a function that takes in the current state of the trading widget and
 * returns a new state with any desired changes applied. This function is used to update the state of
 * the widget based on user interactions or other external factors.
 */
export const useOrderFormCommonState = (
  debug: string,
  params: IOrderFormStateParams,
  manage: (state: IOrderFormState) => IOrderFormState
) => {
  const balanceRef = useRef(getInitialState().balance);
  const selectedOrderKindSignal = useSelectedOrderKindSignal();
  const [state, setState] = useReducer(
    (prev: IOrderFormState = getInitialState(), next: Partial<IOrderFormState>): IOrderFormState => {
      log.state.in(`MarketOrderSection ${debug}`, next);
      const $state = manage({ ...prev, ...next });
      $state.sliderScale = params.pair.amountScale;

      if (debug.includes('stop-limit')) {
        $state.isFormInvalid =
          $state.precise.activationPrice.eq(ZERO) || $state.precise.total.eq(ZERO) || !isFormFieldsValid($state.error);
      } else {
        $state.isFormInvalid = $state.precise.total.eq(ZERO) || !isFormFieldsValid($state.error);
      }

      $state.balance = balanceRef.current;
      log.state.out(`MarketOrderSection ${debug}`, $state);
      return $state;
    },
    getInitialState()
  );

  useEffect(
    () => setState({ marketClosePrice: params.marketClosePrice.toString() }),
    [params.marketClosePrice, params.balance, params.pair]
  );
  useEffect(() => {
    balanceRef.current = {
      ...state.balance,
      value: isNil(params.balance) ? PRISTINE_VALUE : params.balance.toString(),
      isBalanceLoading: false,
    };
    setState({
      balance: balanceRef.current,
    });
  }, [params.pair.id, params.balance]);

  useEffect(() => {
    balanceRef.current.isBalanceLoading = !isEmpty(balanceRef.current.value);
  }, [selectedOrderKindSignal.value]);

  return {
    ...state,
    pair: params.pair,
    set: setState,
    setActivationPrice: (value: string) => setState({ activationPrice: value }),
    setLimitPrice: (value: string) => setState({ limitPrice: value }),
    setPrice: (value: string) => setState({ price: value }),
    setAmount: (value: string) => setState({ amount: value }),
    setAmountMax: () => setState({ amount: state.max.amount.toFixed() }),
    setBalance: (value: string) =>
      setState({ balance: { ...state.balance, value: isNil(value) ? PRISTINE_VALUE : value } }),
    reset: () => setState(getInitialState()),
  };
};

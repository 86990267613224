import clsx from 'clsx';

import style from './style.module.scss';
import { FormStructureStatsCell } from './FormStructureStatsCell';

interface IFormStructureStatsProps extends ICustomizable {
  children: React.ReactNode;
}

export const FormStructureStats = (props: IFormStructureStatsProps) => (
  <div className={clsx(style['form-structure-stats'], props.className)}>{props.children}</div>
);

FormStructureStats.Cell = FormStructureStatsCell;

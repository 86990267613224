import { parsePair } from '@ping/helpers';
import { EmptyViewText, Table, Tooltip } from '@ping/uikit';
import { format } from '@ping/utils';
import { t } from '@ping/helpers';

import style from './style.module.scss';

import type { ITrades } from '@ping/websockets';

interface IMarketHistoryProps {
  selectedMarket: string;
  tradesList: ITrades[];
}

const generateMarketHistory = (instrument = 'N/A_N/A') => [
  {
    Header: 'Time',
    accessor: 'tradeTime',
    Cell: ({ value }) => {
      return (
        <Tooltip
          position={'top'}
          childrenIcon={<>{format.time(value)}</>}
          contentClassName={style['trade-date--tooltip']}
        >
          {format.compactDate(value)}
        </Tooltip>
      );
    },
  },
  {
    Header: () => `Price(${parsePair.byInstrument(instrument).quote})`,
    accessor: 'price',
    columnTextAlignment: 'center',
    Cell: ({ row: { original } }: { row: { original: ITrades } }) => (
      <span className={`status--${original.side === 'Buy' ? 'green' : 'red'}`}>
        {format.number(original.executionPrice)}
      </span>
    ),
  },
  {
    Header: () => `Amount(${parsePair.byInstrument(instrument).base})`,
    accessor: 'amount',
    columnTextAlignment: 'end',
    Cell: ({ value }: { value: string }) => format.number(value),
  },
];

export const MarketHistory = ({ selectedMarket, tradesList }: IMarketHistoryProps) => {
  return (
    <div className={style['market-history']}>
      <h2 className={style['market-history__header']}>{t('Recent Trades')}</h2>
      <div className={style['market-history__table-container']}>
        <Table
          data={tradesList || []}
          columns={generateMarketHistory(selectedMarket)}
          isLoading={!tradesList}
          emptyView={<EmptyViewText text={t('No trades in selected market')} />}
          isMobileStickyColumn={false}
          loadingItemsPerPage={13}
          initialState={{}}
        />
      </div>
    </div>
  );
};

import { WEBSOCKET_URL } from '@ping/configs';
import { marketStore } from '@ping/stores/market.store';

import { useWebSocketMarket } from './useWebSocketMarket';

export type IOrderDetail = {
  price: number;
  amount: number;
  total: number;
};

export type IOrderBook = {
  askTotalAmount: number;
  asks: IOrderDetail[];
  bidTotalAmount: number;
  bids: IOrderDetail[];
  instrument: string;
  snapshot: boolean;
  version?: number;
};

const filterDelayResponseOrderBook = (
  orderBook: IOrderBook,
  interval: ReturnType<typeof setInterval>,
  callback: any
) => {
  if (orderBook.instrument === marketStore.getSelectedMarketSlug()) {
    callback(orderBook);
    clearInterval(interval);
  }
};

export const useOrderBookWS = () => useWebSocketMarket<IOrderBook>(WEBSOCKET_URL, 'Book', filterDelayResponseOrderBook);

import clsx from 'clsx';
import { useContext } from 'react';
import { VisuallyHidden } from 'react-aria';

import { SliderContext } from '../../slider.context';

import style from './style.module.scss';

interface ISliderMileStoneItemProps extends ICustomizable {
  percent: number;
  children?: React.ReactNode;
}

const ZERO = 0 as const;
const ONE_HUNDRED = 100 as const;

export const SliderMileStoneItem = (props: ISliderMileStoneItemProps) => {
  const { thumbIndex, state } = useContext(SliderContext);

  const percentage = state.getValuePercent(state.values[thumbIndex]) || ZERO;

  return (
    <label
      className={clsx(style['slider-milestone-item'], props.className)}
      data-percentage={props.percent}
      data-is-filled={props.percent <= percentage * ONE_HUNDRED}
    >
      <span className={style['slider-milestone-item__label']}>{props.children ?? `${props.percent}%`}</span>

      <VisuallyHidden>
        <input type='radio' name='slider-milestone' value={props.percent} />
      </VisuallyHidden>
    </label>
  );
};

import type { QuoteInfo } from '@ping/api';

/**
 * It returns the market detail for the selected market along with the change and change percentage.
 * @param {string} selectedMarket - A string representing the selected market.
 * @param {[string, IMarketModified[]][]} pairs - pairs is an array of tuples, where each tuple
 * consists of asset name and an array of IMarketModified record.
 */
export const findSelectedMarketDetail = (selectedMarket: string, pairs: [string, IMarketModified[]][]) => {
  const selected = pairs
    .flatMap(([asset, records]) => (selectedMarket.toLowerCase().includes(asset) ? records : []))
    .find(record => record.market === selectedMarket);

  return (selected ?? {}) as QuoteInfo & { change: number; changePercent: number };
};

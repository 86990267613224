import Big from 'big.js';
import clsx from 'clsx';
import { cloneDeep, isEmpty } from 'lodash-es';
import { filter } from 'rambdax';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { useMyTrade } from '@ping/api';
import OrderBookSortAskBidIcon from '@ping/assets/Icon/order-book-sort-ask-bid.svg';
import OrderBookSortAskIcon from '@ping/assets/Icon/order-book-sort-ask.svg';
import OrderBookSortBidIcon from '@ping/assets/Icon/order-book-sort-bid.svg';
import { EOrderSide } from '@ping/enums';
import { t } from '@ping/helpers';
import { useIsUserLoggedIn } from '@ping/hooks';
import { useOrderbookPressedAmountSignal, useSelectedOrderKindSignal, useSelectedOrderSideSignal } from '@ping/signals';
import { selectedMarketInstrumentSelector, useMarketStore } from '@ping/stores/market.store';
import { marketDataStore } from '@ping/stores/marketData.store';
import { orderBookPressedPriceStore } from '@ping/stores/orderBookPressedPrice.store';
import { AggregateController, EmptyViewText, Table, Text } from '@ping/uikit';
import { format, limitDecimalNumber } from '@ping/utils';
import { countNumberDecimals } from '@ping/utils/number-helper.util';
import type { IOrderBook, IOrderDetail, ITrades } from '@ping/websockets';

import { useMarketPair } from '../MarketOrder/MarketOrderForm/OrderForm/helpers';

import { MarketSummary } from './MarketSummary';
import { NumberWithExtraZeros } from './NumberWithExtraZeros';
import style from './style.module.scss';
import { aggregateOrderbookRecords, shiftUserOpenOrderPrices } from './utils';

interface IOrderBookProps {
  orderBookData: IOrderBook;
  selectedMarketInstrument: IMarketModified['instrument'];
  tradesList: ITrades[];
}

const orderBookHeader = (
  instrument = '',
  type: 'asks' | 'bids',
  quoteScale = 2,
  amountScale = 4,
  maxDecimal: number,
  maxAmount: number
) => {
  const [baseCoin, quoteCoin] = instrument.toUpperCase().split('_');
  const { setValue } = orderBookPressedPriceStore;

  return [
    {
      Header: `Price(${quoteCoin || '-'})`,
      accessor: 'price',
      columnTextAlignment: 'start',
      Cell: ({ row: { original } }: { row: { original: IOrderDetail } }) => {
        const numberWithSuffix = format.addSuffix(original.price, true);

        return (
          <div className={style['cell-price']} onClick={() => setValue(original.price)}>
            <span className={style[`status--${type === 'asks' ? 'red' : 'green'}`]}>
              <NumberWithExtraZeros
                value={limitDecimalNumber(numberWithSuffix[0])}
                maxDecimal={maxDecimal}
                suffix={numberWithSuffix[1]}
              />
            </span>
            <div
              className={`${style['cell-after']} ${style[type]}`}
              style={{ width: `${(original.amount * 100) / maxAmount}%` }}
            />
          </div>
        );
      },
    },
    {
      Header: `Amount(${baseCoin || '-'})`,
      accessor: 'amount',
      columnTextAlignment: 'start',
      Cell: ({ value }) => {
        const numberWithSuffix = format.addSuffix(limitDecimalNumber(value), true);

        return (
          <span>
            {new Big(format.exponential(numberWithSuffix[0])).round(amountScale, Big.roundHalfUp).toString() +
              numberWithSuffix[1]}
          </span>
        );
      },
    },
    {
      Header: `Total(${quoteCoin || '-'})`,
      accessor: 'total',
      columnTextAlignment: 'end',
      Cell: ({ row: { original } }) => {
        const numberWithSuffix = format.addSuffix(
          new Big(original.total)
            .round(quoteScale, ['ask', 'sell', 'Sell'].includes(type) ? Big.roundUp : Big.roundDown)
            .toNumber(),
          true
        );

        return <span>{format.exponential(numberWithSuffix[0], quoteScale) + numberWithSuffix[1]}</span>;
      },
    },
  ];
};

/* ToDo: check if we can use Map here without change too many code */
const updateOrderArray = (oldOrdersQue: IOrderDetail[], newOrdersQue: IOrderDetail[]) => {
  return newOrdersQue
    .reduce((accBids, order) => {
      const findIndex = accBids.findIndex(old => old.price === order.price);
      if (findIndex !== -1) {
        accBids[findIndex] = order;
        return accBids;
      }
      return [...accBids, order];
    }, oldOrdersQue)
    .filter(order => order.amount !== 0);
};

const DEFAULT_AGGREGATE_VALUE = 0.01;

export const OrderBook = ({ orderBookData, selectedMarketInstrument }: IOrderBookProps) => {
  const isUserLoggedIn = useIsUserLoggedIn();
  const scrollRefSell = useRef(null);
  const scrollRefBuy = useRef(null);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const market = marketDataStore.getMarketData();
  const selectedMarketData = market.get(selectedMarketInstrument) as IMarketModified;

  const orderBookRef = useRef<IOrderBook>(orderBookData);
  const [prevSelectedMarket, setPrevSelectedMarket] = useState<string>(null);
  const BUY_SELL = `${EOrderSide.BUY}_${EOrderSide.SELL}`;
  const [orderView, setOrderView] = useState(BUY_SELL);
  const [aggregateValue, setAggregateValue] = useState(DEFAULT_AGGREGATE_VALUE);
  const [stopAggregateUpdate, setStopAggregateUpdate] = useState(false);
  const orderBookAggregated = useRef<IOrderBook>({ bids: [], asks: [], ...cloneDeep(orderBookData) });
  const selectedOrderSideSignal = useSelectedOrderSideSignal();
  const selectedOrderKindSignal = useSelectedOrderKindSignal();
  const sellOnly = orderView === EOrderSide.SELL;

  const userOpenOrders = useMyTrade({
    query: {
      enabled: isUserLoggedIn,
      select: filter(order => order.instrument.toLowerCase() === selectedMarketInstrument.toLowerCase()),
    },
  });
  const shiftedUserOpenOrderPrices = useMemo(
    () => shiftUserOpenOrderPrices(userOpenOrders.data, aggregateValue),
    [userOpenOrders.data, aggregateValue]
  );

  const instrument = useMarketStore(selectedMarketInstrumentSelector);
  const quoteScale = useMarketPair(instrument).priceScale;
  const amountScale = useMarketPair(instrument).amountScale;

  useEffect(() => {
    if (quoteScale === undefined) return;

    if (!stopAggregateUpdate) {
      setAggregateValue(selectedMarketData?.close >= 1 ? 0.01 : new Big(10).pow(-quoteScale).toNumber());
    }
  }, [quoteScale, selectedMarketData]);

  useLayoutEffect(() => {
    if (quoteScale === undefined) return;

    setStopAggregateUpdate(false);
    setAggregateValue(selectedMarketData?.close >= 1 ? 0.01 : new Big(10).pow(-quoteScale).toNumber());
  }, [selectedMarketInstrument]);

  const maxDecimal = countNumberDecimals(aggregateValue);
  orderBookRef.current = { ...orderBookData };
  orderBookAggregated.current = cloneDeep(orderBookRef.current);

  if (orderBookData?.asks && orderBookRef.current?.asks) {
    orderBookRef.current.asks = updateOrderArray(orderBookRef.current.asks, orderBookData.asks);
    orderBookAggregated.current.asks = aggregateOrderbookRecords(orderBookRef.current?.asks, aggregateValue, 'ask');
  }
  if (orderBookData?.bids && orderBookRef.current?.bids) {
    orderBookRef.current.bids = updateOrderArray(orderBookRef.current.bids, orderBookData.bids);
    orderBookAggregated.current.bids = aggregateOrderbookRecords(orderBookRef.current?.bids, aggregateValue, 'bid');
  }

  useEffect(() => {
    if (selectedMarketInstrument) {
      if (selectedMarketInstrument !== prevSelectedMarket && orderBookRef.current) {
        orderBookRef.current = null;
      }
      setPrevSelectedMarket(selectedMarketInstrument);
    }
  }, [selectedMarketInstrument]);

  useEffect(() => {
    if (!isUserScrolling && orderView === BUY_SELL) {
      scrollRefSell.current?.scrollTo(0, scrollRefSell.current.scrollHeight);
      scrollRefBuy.current?.scrollTo(0, 0);
    }
  }, [orderBookRef.current]);

  useEffect(() => {
    setIsUserScrolling(false);
  }, [orderBookRef.current.instrument]);

  useEffect(() => {
    if (orderView === BUY_SELL) {
      scrollRefSell.current?.scrollTo(0, scrollRefSell.current.scrollHeight);
    } else {
      scrollRefSell.current?.scrollTo(0, 0);
      scrollRefBuy.current?.scrollTo(0, 0);
    }
  }, [orderView]);

  const findMaxAmount = dataArray => {
    return dataArray?.reduce((max, item) => Math.max(max, item.amount), -Infinity);
  };

  return (
    <div
      className={clsx(style['order-book'], { [style['order-book__buy-sell']]: orderView !== BUY_SELL })}
      data-order-view={orderView.toLowerCase()}
      data-order-side={selectedOrderSideSignal.value}
      data-order-kind={selectedOrderKindSignal.value}
    >
      <div className={style['order-book__heading']}>
        <h2 className={style['order-book__title']}>{t('Order Book')}</h2>
        <div className={style['order-book__button-container']}>
          <button
            onClick={() => setOrderView(BUY_SELL)}
            className={clsx(style['order-book__button'], {
              [style['active-button']]: orderView === BUY_SELL,
            })}
          >
            <OrderBookSortAskBidIcon className={style['order-book__icon']} />
          </button>
          <button
            onClick={() => setOrderView(EOrderSide.SELL)}
            className={clsx(style['order-book__button'], {
              [style['active-button']]: orderView === EOrderSide.SELL,
            })}
          >
            <OrderBookSortAskIcon className={style['order-book__icon']} />
          </button>
          <button
            onClick={() => setOrderView(EOrderSide.BUY)}
            className={clsx(style['order-book__button'], {
              [style['active-button']]: orderView === EOrderSide.BUY,
            })}
          >
            <OrderBookSortBidIcon className={style['order-book__icon']} />
          </button>
        </div>
      </div>

      {orderView.includes(EOrderSide.SELL) && (
        <div
          className={clsx(style['order-book__table'], style['order-book__table-sell-container'], {
            [style['order-book__table-sell-only']]: sellOnly,
          })}
          ref={scrollRefSell}
          onScroll={() => setIsUserScrolling(true)}
        >
          <div className={style['order-book__table-sell-header']}>
            {orderBookHeader(
              orderBookRef.current?.instrument,
              'asks',
              quoteScale,
              amountScale,
              maxDecimal,
              findMaxAmount(orderBookRef.current?.asks)
            ).map(item => (
              <div key={item.Header} className={style['order-book__table-sell-header__item']}>
                <Text className={style['order-book__table-sell-header__text']}>{item.Header}</Text>
              </div>
            ))}
          </div>
          <Table
            data={orderBookAggregated.current.asks}
            columns={orderBookHeader(
              orderBookRef.current?.instrument,
              'asks',
              quoteScale,
              amountScale,
              maxDecimal,
              findMaxAmount(orderBookRef.current?.asks)
            )}
            emptyView={
              <EmptyViewText className={style['order-book__empty-view']} text={t('No asks in selected market')} />
            }
            isLoading={isEmpty(orderBookRef.current)}
            loadingItemsPerPage={9}
            isMobileStickyColumn={false}
            className={style['order-book-table']}
            disableAllColumnsSorting
            hasScrollRef
            getRowProps={row => ({
              'data-indicated': shiftedUserOpenOrderPrices.sell.has(row.original.price),
              'data-record-side': 'sell',
              onClick: (ev: Event) => {
                ev.preventDefault();
                ev.stopPropagation();

                if (selectedOrderSideSignal.value === EOrderSide.BUY.toLowerCase()) {
                  useOrderbookPressedAmountSignal.set(row.original.amount);
                }
              },
            })}
          />
        </div>
      )}

      <div className={style['order-book__table-buy-container']}>
        <MarketSummary maxDecimal={maxDecimal} />

        {orderView.includes(EOrderSide.BUY) && (
          <div className={style['order-book__table']} ref={scrollRefBuy} onScroll={() => setIsUserScrolling(true)}>
            <Table
              data={orderBookAggregated.current.bids}
              columns={orderBookHeader(
                orderBookAggregated.current?.instrument,
                'bids',
                quoteScale,
                amountScale,
                maxDecimal,
                findMaxAmount(orderBookRef.current?.bids)
              )}
              emptyView={<EmptyViewText text={t('No bids in selected market')} />}
              loadingItemsPerPage={9}
              isLoading={isEmpty(orderBookRef.current)}
              isMobileStickyColumn={false}
              className={style['order-book__second-header']}
              disableAllColumnsSorting
              hasScrollRef
              getRowProps={row => ({
                'data-indicated': shiftedUserOpenOrderPrices.buy.has(row.original.price),
                'data-record-side': 'buy',
                onClick: (ev: Event) => {
                  ev.preventDefault();
                  ev.stopPropagation();

                  if (selectedOrderSideSignal.value === EOrderSide.SELL.toLowerCase()) {
                    useOrderbookPressedAmountSignal.set(row.original.amount);
                  }
                },
              })}
            />
          </div>
        )}
      </div>

      {quoteScale && (
        <AggregateController
          defaultNumber={selectedMarketData?.close >= 1 ? 0.01 : new Big(10).pow(-quoteScale).toNumber()}
          onChange={selectedNumber => {
            setStopAggregateUpdate(true);
            setAggregateValue(selectedNumber);
          }}
          value={aggregateValue}
          maxNumber={selectedMarketData?.close >= 1 ? 0.01 : 1}
          minNumber={selectedMarketData?.close >= 1 ? 0.0001 : new Big(10).pow(-quoteScale).toNumber()}
          className={style['order-book__aggregate']}
        />
      )}
    </div>
  );
};

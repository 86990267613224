import { useMemo } from 'react';
import { PortfolioItemResponse } from '@ping/api';
import { ECurrency } from '@ping/enums';
import { t } from '@ping/helpers';
import { ActionTooltip, CoinIcon, Text } from '@ping/uikit';
import { fixAssetAmountFour, fixNumber, format } from '@ping/utils';
import { useSelectedCurrency } from '@ping/hooks';
import { selectedLanguageSelector, usePreferencesStore } from '@ping/stores/preferences.store';

interface IColumnsList {
  getDropDownList: (assetId) => any[];
}

const getColumnsList = ({ getDropDownList }: IColumnsList) => {
  const selectedCurrency = useSelectedCurrency() as ECurrency;
  const selectedLanguage = usePreferencesStore(selectedLanguageSelector);

  return useMemo(
    () => [
      {
        Header: t('Currency'),
        accessor: 'assetId',
        Cell: ({ value }: { value: string }) => (
          <div className={'assetName-wrapper'}>
            <CoinIcon className={'asset-icon'} name={value.toUpperCase()} />
            {`${value.toUpperCase()}`}
          </div>
        ),
        sticky: 'left',
      },
      {
        Header: t('Amount'),
        accessor: 'amount',
        Cell: ({ row: { original } }: { row: { original: PortfolioItemResponse } }) => (
          <span>
            {format.number(fixAssetAmountFour(original.amount))}
            &nbsp;
            <Text badge cadetBlueColor uppercase>
              {original.assetId}
            </Text>
          </span>
        ),
      },
      {
        Header: t(`Amount (%s)`, selectedCurrency || 'N/A'),
        accessor: 'balance',
        Cell: ({ value }: { value: string }) => (
          <span>
            {format.number(fixAssetAmountFour(value))}
            &nbsp;
            <Text badge cadetBlueColor uppercase>
              {selectedCurrency}
            </Text>
          </span>
        ),
      },
      {
        Header: t('Percentage of total'),
        accessor: 'percentageOfTotal',
        Cell: ({ value }: { value: string }) => <>{fixNumber(value)}%</>,
      },
      {
        Header: '',
        id: 'dropDownColumn',
        accessor: 'assetId',
        disableColumnSorting: true,
        Cell: ({ value }: { value: string }) => <ActionTooltip dropDownList={getDropDownList(value)} />,
      },
    ],
    [selectedCurrency, selectedLanguage]
  );
};

export default getColumnsList;

import style from './style.module.scss';

interface IEmptyOrderBook {
  text: string;
  className?: string;
}

export const EmptyViewText = ({ text, className = '' }: IEmptyOrderBook) => (
  <div className={`${style['empty-view-text']} ${className}`}>
    <span>{text || 'No data'}</span>
  </div>
);

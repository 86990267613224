import { useEffect } from 'react';
import clsx from 'clsx';
import { getGetPortfolioQueryKey, useCancelAllActiveOrdersTrade, useCancelTrade, useMyTrade } from '@ping/api';
import { OVERRIDE_ON_ERROR } from '@ping/constants/override-on-error.constant';
import { t } from '@ping/helpers';
import { TableWithVirtualList, Toast } from '@ping/uikit';
import { queryClient } from '@ping/providers/ReactQueryProvider';
import NoDataIcon from '@ping/assets/Icon/no-data-found.svg';
import { isUserSupportSelector, useUserInformationStore } from '@ping/stores/userInformation.store';

import getColumnsList from './getColumnsList';

import style from './style.module.scss';

const OpenOrdersTable = ({ selectedTab, isUserLoggedIn, className = '' }: IExchangeOrdersTabProps) => {
  const isUserSupport = useUserInformationStore(isUserSupportSelector);

  // Open Orders
  const {
    data: openOrders,
    isLoading: isOpenOrdersLoading,
    queryKey: openOrdersKey,
  } = useMyTrade({ query: { enabled: !isUserSupport && isUserLoggedIn ? selectedTab === 'openOrders' : false } });
  // Delete Order
  const {
    mutate: deleteOrder,
    isLoading: isDeleteOrderLoading,
    variables: deleteOrderVariables,
  } = useCancelTrade(OVERRIDE_ON_ERROR);
  // Delete All Orders
  const { mutate: deleteAllOrders, isLoading: isDeleteAllOrdersLoading } = useCancelAllActiveOrdersTrade();

  const handleDeleteOrder = id =>
    deleteOrder(
      {
        orderId: id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(openOrdersKey);
          queryClient.invalidateQueries(getGetPortfolioQueryKey());
          Toast.success({ title: t('The selected order has been deleted successfully') });
        },
        onError: () => {
          Toast.error({ title: t('An error occurred while deleting the selected order') });
        },
      }
    );

  const handleDeleteAllOrders = () => {
    deleteAllOrders(null, {
      onSuccess: () => {
        queryClient.invalidateQueries(openOrdersKey);
        queryClient.invalidateQueries(getGetPortfolioQueryKey());
        Toast.success({ title: t('All the orders deleted successfully') });
      },
      onError: () => {
        Toast.error({ title: t('An error occurred while deleting all the orders') });
      },
    });
  };

  const openOrdersList = getColumnsList({
    openOrders,
    deleteOrderVariables,
    isDeleteOrderLoading,
    isDeleteAllOrdersLoading,
    handleDeleteOrder,
    handleDeleteAllOrders,
  });

  //TODO: update the cache for all orders that were canceled simultaneously
  useEffect(() => {
    queryClient.invalidateQueries(openOrdersKey);
  }, [openOrders]);

  if (!isOpenOrdersLoading && (!openOrders || openOrders?.length === 0)) {
    return (
      <div
        className={clsx('trading-records__no-data-icon', {
          [style['open-orders-table__no-data-icon-margin-top']]: isUserLoggedIn,
        })}
      >
        <NoDataIcon />
      </div>
    );
  }

  return (
    <div className={className}>
      <TableWithVirtualList
        data={openOrders || []}
        columns={openOrdersList}
        classNameContainer={style['open-orders-table']}
        isLoading={isOpenOrdersLoading}
      />
    </div>
  );
};

export default OpenOrdersTable;

import { useMemo } from 'react';

import { modalKeys } from '@ping/constants/modal-keys.constant';
import { t } from '@ping/helpers';
import { useRemoteModal } from '@ping/hooks';
import { Button, Modal } from '@ping/uikit';

import style from './style.module.scss';

type TMarketWarning = {
  title: string;
  description: string;
};

const WarningListItem = ({ title, description }) => {
  return (
    <li>
      <span className={style['warning-modal__list-item_title']}>{title}</span> - <span>{description}</span>
    </li>
  );
};

export const MarketWarningModal = () => {
  const modal = useRemoteModal(modalKeys.MARKET_ORDER_WARNING);

  const handleAgreement = () => {
    const oldUiHelperObject = JSON.parse(sessionStorage.getItem('UI_HELPER'));

    const uiHelperObject = {
      ...oldUiHelperObject,
      marketOrderWarning: true,
    };
    sessionStorage.setItem('UI_HELPER', JSON.stringify(uiHelperObject));
    modal.state.close();
  };

  const WARNING_ITEMS_LIST: TMarketWarning[] = useMemo(
    () => [
      {
        title: t('Low Liquidity'),
        description: t(
          'Markets with low trading volume may have limited buyers and sellers, making it harder to execute trades at desired prices.'
        ),
      },
      {
        title: t('Price Slippage'),
        description: t(
          'Market orders are executed at the best available price, which may differ from the quoted price at the time of placing the order, resulting in slippage.'
        ),
      },
      {
        title: t('Wide Bid-Ask Spreads'),
        description: t(
          'In low-volume markets, the gap between the highest bid price and the lowest ask price (bid-ask spread) may be wider, potentially increasing trading costs.'
        ),
      },
      {
        title: t('Limited Order Book Depth'),
        description: t(
          'The order book may lack depth, making it challenging to predict and respond to price movements effectively.'
        ),
      },
    ],
    [t]
  );

  return (
    <Modal.Root {...modal.props}>
      <Modal.Portal divide isDismissable isKeyboardDismissDisabled>
        <Modal.Header className={style['warning-modal__header']}>
          <h4>{t('Important notice')}</h4>
        </Modal.Header>
        <Modal.Content className={style['warning-modal__content']}>
          <p>{t('Trading with market orders involves certain risks that you should be aware of before proceeding.')}</p>
          <ul className={style['warning-modal__list']}>
            {WARNING_ITEMS_LIST.map((item, index) => (
              <WarningListItem key={index} {...item} />
            ))}
          </ul>
          <p>
            {t(
              'By proceeding with a market order, you acknowledge and accept these risks. Consider using limit orders for better control over your trade execution.'
            )}
          </p>
        </Modal.Content>
        <Modal.Actions className={style['warning-modal__action']}>
          <Button onClick={handleAgreement} type='primary' design='general' uppercase htmlType='submit'>
            {t('Agree & Continue')}
          </Button>
        </Modal.Actions>
      </Modal.Portal>
    </Modal.Root>
  );
};

import { useCallback } from 'react';

import { Store } from '@ping/utils';

interface IMarketOrderConfirmationSignal {
  value: boolean;
}

// --------------------------------------------------------------------------------
// MARKET ORDER CONFIRMATION SIGNAL
// --------------------------------------------------------------------------------
const marketOrderConfirmationSignal = new Store<IMarketOrderConfirmationSignal>({ value: true })
  .withPersist({ name: 'MARKET_ORDER_CONFIRMATION_SIGNAL' })
  .withDevTools()
  .build();

// --------------------------------------------------------------------------------
// MARKET ORDER CONFIRMATION HOOK
// --------------------------------------------------------------------------------
export const useMarketOrderConfirmationSignal = () => {
  const isConfirmationNeeded = marketOrderConfirmationSignal(state => state.value);
  const setConfirmationNeeded = useCallback(
    (input: boolean) => marketOrderConfirmationSignal.setState({ value: input }),
    []
  );

  return Object.freeze({ setConfirmationNeeded, isConfirmationNeeded });
};

// --------------------------------------------------------------------------------
// MARKET ORDER CONFIRMATION NON-REACT
// --------------------------------------------------------------------------------
useMarketOrderConfirmationSignal.getConfirmationNeeded = () => marketOrderConfirmationSignal.getState().value;
useMarketOrderConfirmationSignal.setConfirmationNeeded = (input: boolean) => {
  return marketOrderConfirmationSignal.setState({ value: input });
};

import { isNil } from 'rambdax';
import { useEffect, useMemo } from 'react';

import { useSwapTrade } from '@ping/api';
import { DEFAULT_CURRENCY } from '@ping/configs';
import { parsePair, scientificNumbersParser, t } from '@ping/helpers';
import { useSelectedCurrency } from '@ping/hooks';
import { selectedMarketInstrumentSelector, useMarketStore } from '@ping/stores/market.store';
import { marketDataStore } from '@ping/stores/marketData.store';
import { orderBookPressedPriceStore } from '@ping/stores/orderBookPressedPrice.store';
import { fixNumber, format, limitDecimalNumber } from '@ping/utils';

import { NumberWithExtraZeros } from '../NumberWithExtraZeros';
import { determineMarketPairFluctuation } from '../../MarketOrder/MarketOrderPair/SelectedMarketPairState';

import style from './style.module.scss';

const ONE = 1 as const;

interface IMarketSummaryProps {
  maxDecimal: number;
}

export const MarketSummary = ({ maxDecimal }: IMarketSummaryProps) => {
  const market = marketDataStore.getMarketData();
  const selectedMarketInstrument = useMarketStore(selectedMarketInstrumentSelector);

  const selectMarketData = useMemo(() => {
    if (market.size === 0) return null;

    const selectedMarketData = market.get(selectedMarketInstrument) as IMarketModified;
    if (selectedMarketData === undefined) return null;

    selectedMarketData.change = selectedMarketData.close - selectedMarketData.open;
    selectedMarketData.changePercent =
      selectedMarketData.open !== 0 ? (selectedMarketData.change / selectedMarketData.open) * 100 : 0;
    return selectedMarketData;
  }, [market, selectedMarketInstrument]);

  // Prefill the initial price and limit-price values at the order form section
  useEffect(
    () => orderBookPressedPriceStore.setValue(selectMarketData?.close),
    [isNil(selectMarketData?.close), selectMarketData?.instrument]
  );

  const pair = parsePair.byInstrument(selectMarketData?.instrument);
  const selectedCurrency = useSelectedCurrency();

  const { data: swapTradeData } = useSwapTrade(
    {
      FromAssetId: pair.quote?.toLowerCase(),
      ToAssetId: selectedCurrency.toLowerCase(),
      Amount: ONE,
      DefaultCurrency: DEFAULT_CURRENCY.toLowerCase(),
    },
    {
      query: {
        enabled: !!(pair.quote && selectedCurrency.toLowerCase()),
      },
    }
  );

  const priceNumberWithSuffix = format.addSuffix(selectMarketData?.close, true);
  const currencyNumberWithSuffix = format.addSuffix(
    limitDecimalNumber(swapTradeData?.rate * selectMarketData?.close),
    true
  );

  return (
    <dl className={style['market-summary']}>
      <div className={style['market-summary__group']}>
        <dt className={style['market-summary__title']}>{t('Last price')}</dt>
        <dd className={style['market-summary__value']}>
          {selectMarketData?.close ? (
            <NumberWithExtraZeros
              value={limitDecimalNumber(priceNumberWithSuffix[0])}
              maxDecimal={maxDecimal}
              suffix={priceNumberWithSuffix[1]}
            />
          ) : (
            'N/A'
          )}
        </dd>
      </div>

      <div className={style['market-summary__group']}>
        <dt className={style['market-summary__title']}>{selectedCurrency}</dt>
        <dd className={style['market-summary__value']}>
          {scientificNumbersParser(fixNumber(currencyNumberWithSuffix[0])) + currencyNumberWithSuffix[1] || 'N/A'}
        </dd>
      </div>

      <div className={style['market-summary__group']}>
        <dt className={style['market-summary__title']}>{t('Change')}</dt>
        <dd
          className={style['market-summary__value']}
          data-fluctuation={determineMarketPairFluctuation(selectMarketData).state}
        >
          {selectMarketData?.changePercent === 0 || selectMarketData?.changePercent
            ? `${fixNumber(selectMarketData?.changePercent)}%`
            : 'N/A'}
        </dd>
      </div>
    </dl>
  );
};

import { isNil } from '@ping/utils';
import { selectedMarketInstrumentSelector, useMarketStore } from '@ping/stores/market.store';

import { useMarketPair, usePortfolio } from '../helpers';
import { OrderFormStructure } from '../OrderFormStructure';
import { useStopLimitSellFormState } from './stop-limit-sell-form-state.hook';

import type { IMarketDataType } from '@ping/websockets';

const ZERO = 0 as const;

interface IStopLimitOrderSellFormProps {
  market: IMarketDataType;
  isDisabled?: boolean;
}

export const StopLimitOrderSellForm = (props: IStopLimitOrderSellFormProps) => {
  const instrument = useMarketStore(selectedMarketInstrumentSelector);
  const pair = useMarketPair(instrument);
  const { portfolio, isPortfolioFetched } = usePortfolio();
  const balance = isPortfolioFetched ? portfolio.get(pair.baseAsset)?.availableAmount : ZERO;

  const sellState = useStopLimitSellFormState({
    pair,
    balance,
    marketClosePrice: props.market?.close ?? ZERO,
  });

  return (
    <OrderFormStructure
      orderKind='stop-limit'
      orderSide='sell'
      instrument={instrument}
      state={sellState}
      isDisabled={props.isDisabled || isNil(balance) || balance === ZERO}
    />
  );
};

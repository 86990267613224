import { useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import { useRecentTrades } from '@ping/api';
import { Footer, Page } from '@ping/components';
import { WEBSOCKET_URL } from '@ping/configs';
import { t } from '@ping/helpers';
import { getInstrumentPair, getMarketPair } from '@ping/helpers/parse-pair.helper';
import { useDesktopDevice, useIsUserLoggedIn } from '@ping/hooks';
import { marketStore, selectedMarketInstrumentSelector, useMarketStore } from '@ping/stores/market.store';
import {
  isUserActivityStatusActiveSelector,
  isUserAdminSelector,
  isUserComplianceReviewerSelector,
  isUserTradeDataViewerSelector,
  isUserActivityStatusBannedSelector,
  useUserInformationStore,
} from '@ping/stores/userInformation.store';
import { Tabs, Toast } from '@ping/uikit';
import { ITrades, useMarketMoneyWS, useNotificationWS, useOrderBookWS, useTradeHistoryWS } from '@ping/websockets';

import { MarketHistory, MarketOrder, OrderBook, TradingChart, TradingRecords } from './components';
import style from './style.module.scss';

import type { NextPage } from 'next';

/**
 * Returns the latest 50 recent trades based on the tradeId.
 *
 * @param {...ITrades[]} trades - An array of ITrades objects representing the trades.
 * @return {ITrades[]} An array of ITrades objects representing the latest 50 recent trades.
 */
const getLatestRecentTrades = (...trades: ITrades[]) => {
  return trades.sort((x, y) => y.tradeId - x.tradeId).slice(0, 50);
};

export const Trade: NextPage = () => {
  //
  // NOTE: heavy to load, prevent rendering. it's especially noticeable after
  //       successful login when we redirect authenticated users to the trade page.
  const isUserComplianceReviewer = useUserInformationStore(isUserComplianceReviewerSelector);
  const isUserTradeDataViewer = useUserInformationStore(isUserTradeDataViewerSelector);
  const isUserAdmin = useUserInformationStore(isUserAdminSelector);
  const [, setSelectedTab] = useState('chart');

  if (isUserComplianceReviewer) {
    return <Page />;
  }

  if (isUserTradeDataViewer && !isUserAdmin) {
    return <Page />;
  }

  const isUserLoggedIn = useIsUserLoggedIn();
  const isUserActivityStatusActive = useUserInformationStore(isUserActivityStatusActiveSelector);
  const isUserActivityStatusBanned = useUserInformationStore(isUserActivityStatusBannedSelector);

  useEffect(() => {
    if (isUserActivityStatusBanned) {
      Toast.error({ title: t('User is temporarily muted.') });
    }
  }, [isUserActivityStatusBanned]);

  const [, setQueryParamMarket] = useQueryParam('market', StringParam);
  const market = useMarketMoneyWS(WEBSOCKET_URL);
  const selectedMarketInstrument = useMarketStore(selectedMarketInstrumentSelector);
  const [tradesList, setTradesList] = useState([]);

  if (!market.data.has(getInstrumentPair(selectedMarketInstrument)) && market.data.size > 0) {
    marketStore.setSelectedMarket(getMarketPair(market.data.entries().next().value[0]));
  }

  const books = useOrderBookWS();
  const trades = useTradeHistoryWS();
  const isDesktop = useDesktopDevice();
  //
  // Since we need the status of the notification WebSocket in the SocketConnectivityIndicator component, I put it here.
  useNotificationWS();
  const { data } = useRecentTrades({
    Instrument: selectedMarketInstrument,
  });

  useEffect(() => {
    if (data) {
      setTradesList(data);
    }
  }, [data]);

  useEffect(() => {
    if (!trades) {
      return;
    }

    setTradesList(previous => getLatestRecentTrades(...previous, ...trades));
  }, [trades]);

  useEffect(() => {
    setQueryParamMarket(selectedMarketInstrument);
  }, [selectedMarketInstrument]);

  /* force re-render for OrderBook to make ScrollTo works since it doesn't work when the component is
   hidden with css style */
  const handleSelectedTab = (key: string) => {
    setSelectedTab(key);
  };

  return (
    <>
      {isUserLoggedIn && !isUserActivityStatusActive && <Page />}
      <Page>
        <div className={style['main-container']}>
          <MarketOrder />

          {!isDesktop && (
            <Tabs.Root
              className={style['trade-widgets-tabs']}
              variant='secondary'
              defaultSelectedKey='chart'
              onSelectionChange={handleSelectedTab}
              isEven
              isMountable
              disabledKeys={!isUserLoggedIn ? ['orders'] : []}
            >
              <Tabs.Item key='charts' title={t('Charts')}>
                <TradingChart />
              </Tabs.Item>
              <Tabs.Item key='book' title={t('Book')}>
                <OrderBook
                  selectedMarketInstrument={selectedMarketInstrument}
                  orderBookData={books}
                  tradesList={tradesList}
                />
              </Tabs.Item>
              <Tabs.Item key='trades' title={t('Trades')}>
                <MarketHistory selectedMarket={selectedMarketInstrument} tradesList={tradesList} />
              </Tabs.Item>
              <Tabs.Item key='orders' title={t('Orders')}>
                <TradingRecords />
              </Tabs.Item>
            </Tabs.Root>
          )}

          {isDesktop && (
            <>
              <TradingChart />
              <OrderBook
                selectedMarketInstrument={selectedMarketInstrument}
                orderBookData={books}
                tradesList={tradesList}
              />
              <MarketHistory selectedMarket={selectedMarketInstrument} tradesList={tradesList} />
              <TradingRecords />
            </>
          )}
        </div>
        <Footer />
      </Page>
    </>
  );
};

export default Trade;

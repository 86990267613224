import { Big } from 'big.js';
import { useState } from 'react';
import { VisuallyHidden } from 'react-aria';

import { useQuoteTrade } from '@ping/api';
import { calculate, scientificNumbersParser, t } from '@ping/helpers';
import { useEstimatedWeightedTotal, useIsUserLoggedIn } from '@ping/hooks';
import { useMarketOrderConfirmationSignal } from '@ping/signals';
import { Button, Checkbox, ComboField, Divider, Modal, NumberField, Slider, Timer, Toast, Tooltip } from '@ping/uikit';
import { format, normalize } from '@ping/utils';

import { useIsNormalPriceComparison, type useOrderFormCommonState } from '../helpers';

import { AmountNumberField } from './AmountNumberField';
import { FormStructure } from './FormStructure';
import style from './style.module.scss';

const ZERO = 0 as const;
const SLIDER_STEPS = 1000 as const;
const MIN_SLIDER_STEP = 0.00000001 as const;
const MARKET_ORDER_CONFIRMATION_TIMEOUT = 10 as const; // Seconds

enum ESliderStep {
  ZERO_PERCENT = 0,
  TWENTY_FIVE_PERCENT = 25,
  FIFTY_PERCENT = 50,
  SEVENTY_FIVE_PERCENT = 75,
  HUNDRED_PERCENT = 100,
}

interface IOrderFormStructureProps {
  instrument: string;
  isDisabled?: boolean;
  orderKind: IOrderKind;
  orderSide: IOrderSide;
  state: ReturnType<typeof useOrderFormCommonState>;
  isNormalPrice?: boolean;
  isUserLoggedIn?: boolean;
  percentage?: {
    value: string;
    range: string;
  };
}

//
// Helpers
const isStopPriceFieldAccessible = (type: IOrderKind) => ['stop-limit'].includes(type);
const isLimitPriceFieldAccessible = (type: IOrderKind) => ['stop-limit'].includes(type);
const isPriceFieldAccessible = (type: IOrderKind) => ['limit'].includes(type);
const isLimitOrder = (orderKind: IOrderKind) => orderKind !== 'market';
const isStopOrder = (orderKind: IOrderKind) => orderKind === 'stop-limit';

/**
 * It calculates the step value for a slider based on a maximum amount.
 * @param {Big} maxAmount - `maxAmount` is a variable of type `Big` which represents the maximum amount
 * that can be selected on a slider.
 * @returns The function `getAmountSliderStep` returns a `Big` number representing the step value for a
 * slider based on the maximum amount and the number of slider steps defined in the constant
 * `SLIDER_STEPS`. If the calculated step value is less than the minimum slider step defined in the
 * constant `MIN_SLIDER_STEP`, the function returns `MIN_SLIDER_STEP`.
 */
export const getAmountSliderStep = (maxAmount: Big) => {
  const stepValue = maxAmount.div(SLIDER_STEPS);
  return stepValue.lt(MIN_SLIDER_STEP) ? Big(MIN_SLIDER_STEP) : stepValue;
};

//
// Order form fields
const OrderActivationPriceField = ({ state, isDisabled }: Pick<IOrderFormStructureProps, 'state' | 'isDisabled'>) => (
  <div className={style['form-field']}>
    <ComboField invalid={Boolean(state.error.activationPrice)}>
      <NumberField
        name='activationPrice'
        label={t('Stop price')}
        magnet='end'
        isDisabled={isDisabled}
        value={state.activationPrice}
        minValue={String(state.pair.minPrice ?? '')}
        maxValue={state.max.activationPrice.toString()}
        placeholder={t('Please enter Stop price')}
        onChange={state.setActivationPrice}
        formatOptions={{ maximumFractionDigits: state.pair.priceScale }}
      />
      <ComboField.Divider />
      <ComboField.Box className={style['asset-name']} magnet='start'>
        {state.pair.quoteAsset.toUpperCase()}
      </ComboField.Box>
    </ComboField>

    <span className={style['form-message']} aria-hidden={!state.error.activationPrice}>
      {state.error.activationPrice}
    </span>
  </div>
);

const OrderLimitPriceField = ({ state, isDisabled }: Pick<IOrderFormStructureProps, 'state' | 'isDisabled'>) => (
  <div className={style['form-field']}>
    <ComboField invalid={Boolean(state.error.limitPrice)}>
      <NumberField
        name='price'
        label={t('Limit price')}
        magnet='end'
        isDisabled={isDisabled}
        value={state.limitPrice}
        minValue={String(state.pair.minPrice ?? '')}
        maxValue={state.max.limitPrice.toString()}
        placeholder={t('Please enter Limit price')}
        onChange={state.setLimitPrice}
        formatOptions={{ maximumFractionDigits: state.pair.priceScale }}
      />
      <ComboField.Divider />
      <ComboField.Box className={style['asset-name']} magnet='start'>
        {state.pair.quoteAsset.toUpperCase()}
      </ComboField.Box>
    </ComboField>

    <span className={style['form-message']} aria-hidden={!state.error.limitPrice}>
      {state.error.limitPrice}
    </span>
  </div>
);

const OrderPriceField = ({
  state,
  isDisabled,
  isNormalPrice,
  percentage,
  isUserLoggedIn,
}: Pick<IOrderFormStructureProps, 'state' | 'isDisabled' | 'isNormalPrice' | 'isUserLoggedIn' | 'percentage'>) => (
  <div className={style['form-field']}>
    <ComboField invalid={Boolean(state.error.price)}>
      <NumberField
        name='price'
        label={t('Price')}
        magnet='end'
        isDisabled={isDisabled}
        value={state.price}
        minValue={String(state.pair.minPrice ?? '')}
        maxValue={state.max.price.toString()}
        placeholder={t('Please enter price')}
        onChange={state.setPrice}
        formatOptions={{ maximumFractionDigits: state.pair.priceScale }}
      />
      <ComboField.Divider />
      {isUserLoggedIn && !isNormalPrice && state.price != '' ? (
        <Tooltip
          position='top'
          showTooltip={!isNormalPrice}
          contentClassName={style['tooltip-info']}
          childrenIcon={
            <ComboField.Box className={style['asset-name']} magnet='start'>
              {state.pair.quoteAsset.toUpperCase()}
            </ComboField.Box>
          }
        >
          {t('%s% %s than the best offer', format.exponential(percentage?.value), percentage?.range)}
        </Tooltip>
      ) : (
        <ComboField.Box className={style['asset-name']} magnet='start'>
          {state.pair.quoteAsset.toUpperCase()}
        </ComboField.Box>
      )}
    </ComboField>

    <span className={style['form-message']} aria-hidden={!state.error.price}>
      {state.error.price}
    </span>
  </div>
);

const OrderAmountField = ({ state, isDisabled }: Pick<IOrderFormStructureProps, 'state' | 'isDisabled'>) => (
  <div className={style['form-field']}>
    <ComboField invalid={Boolean(state.error.amount)}>
      <AmountNumberField
        label={t('Amount')}
        name='amount'
        magnet='end'
        isDisabled={isDisabled || state.isMaxButtonLoading}
        value={state.amount}
        minValue={String(state.pair.minAmount ?? '')}
        maxValue={state.max.amount.toString()}
        placeholder={t('Please enter amount')}
        onChange={state.setAmount}
        formatOptions={{ maximumFractionDigits: state.pair.amountScale }}
      />

      <ComboField.Button
        data-visible={state.isMaxButtonVisible}
        isDisabled={isDisabled || state.isMaxButtonLoading || state.max.amount.eq(ZERO)}
        isLoading={state.isMaxButtonLoading}
        magnet='both'
        onPress={state.setAmountMax}
      >
        {t('MAX')}
      </ComboField.Button>

      <ComboField.Divider />
      <ComboField.Box className={style['asset-name']} magnet='start'>
        {state.pair.baseAsset.toUpperCase()}
      </ComboField.Box>
    </ComboField>

    <span className={style['form-message']} aria-hidden={!state.error.amount}>
      {state.error.amount}
    </span>
  </div>
);

export const OrderFormStructure = ({ state, ...props }: IOrderFormStructureProps) => {
  const { isNormalPrice, percentage } = useIsNormalPriceComparison(state.price);
  const isUserLoggedIn = useIsUserLoggedIn();

  const estimatedWeightedTotal = useEstimatedWeightedTotal(
    state.precise.amount,
    props.orderSide,
    state.pair.priceScale
  );

  const [marketConfirmationModalVisibility, setMarketConfirmationModalVisibility] = useState(false);
  const [isTimerPlaying, setIsTimerPlaying] = useState(false);
  const [quoteTradeEnabled, setQuoteTradeEnabled] = useState(false);
  const quoteTrade = useQuoteTrade(
    {
      Amount: state.amount as unknown as number,
      Instrument: props.instrument,
      IsBuy: props.orderSide === 'buy',
    },
    {
      query: {
        enabled: quoteTradeEnabled,
        onSuccess: () => {
          setIsTimerPlaying(true);
        },
        onError: err => {
          Toast.error({ title: (err.response.data as any).message || t('Something went wrong!') });
          setIsTimerPlaying(false);
          setMarketConfirmationModalVisibility(false);
        },
        onSettled: () => {
          setQuoteTradeEnabled(false);
        },
      },
    }
  );

  const updateDetailsOnTimerChange = (remaining: number) => {
    if (remaining !== ZERO) {
      return;
    }

    quoteTrade.refetch();
  };

  const enableQuoteTradeOnSubmit = () => {
    if (!useMarketOrderConfirmationSignal.getConfirmationNeeded()) {
      return;
    }

    setQuoteTradeEnabled(true);
    setMarketConfirmationModalVisibility(true);
  };

  return (
    <>
      <FormStructure.Root onSuccess={state.reset} marketPrice={quoteTrade.data?.price} id='order-form'>
        <input name='isLimit' value={isLimitOrder(props.orderKind).toString()} type='hidden' />
        <input name='isStop' value={isStopOrder(props.orderKind).toString()} type='hidden' />
        <input name='type' value={props.orderSide} type='hidden' />
        <input name='instrument' value={props.instrument} type='hidden' />

        <FormStructure.Group hidden={!isStopPriceFieldAccessible(props.orderKind)}>
          <OrderActivationPriceField state={state} isDisabled={props.isDisabled} />
        </FormStructure.Group>

        <FormStructure.Group hidden={!isLimitPriceFieldAccessible(props.orderKind)}>
          <OrderLimitPriceField state={state} isDisabled={props.isDisabled} />
        </FormStructure.Group>

        <FormStructure.Group hidden={!isPriceFieldAccessible(props.orderKind)}>
          <OrderPriceField
            state={state}
            isDisabled={props.isDisabled}
            isNormalPrice={isNormalPrice}
            isUserLoggedIn={isUserLoggedIn}
            percentage={percentage}
          />
        </FormStructure.Group>

        <FormStructure.Group>
          <OrderAmountField state={state} isDisabled={props.isDisabled} />
          <FormStructure.Balance balance={state.balance} pair={state.pair} orderSide={props.orderSide} />
          <Slider.Root
            className={style['amount-slider']}
            isDisabled={props.isDisabled || state.isAmountSliderDisabled}
            preciseMaxValue={state.max.amount}
            preciseStep={getAmountSliderStep(state.max.amount)}
            value={Number(state.amount)}
            scale={state.sliderScale}
            onChangeValue={value => state.setAmount(scientificNumbersParser(Number(value)))}
          >
            <VisuallyHidden>
              <Slider.Label>{t('Amount slider')}</Slider.Label>
            </VisuallyHidden>
            <Slider.Track>
              <Slider.Thumb />
              <Slider.MileStones>
                <Slider.MileStones.Item percent={ESliderStep.ZERO_PERCENT} />
                <Slider.MileStones.Item percent={ESliderStep.TWENTY_FIVE_PERCENT} />
                <Slider.MileStones.Item percent={ESliderStep.FIFTY_PERCENT} />
                <Slider.MileStones.Item percent={ESliderStep.SEVENTY_FIVE_PERCENT} />
                <Slider.MileStones.Item percent={ESliderStep.HUNDRED_PERCENT} />
              </Slider.MileStones>
            </Slider.Track>
          </Slider.Root>
        </FormStructure.Group>

        <FormStructure.Divider />

        <FormStructure.Stats>
          <FormStructure.FeeRow pair={state.pair} feePercentage={calculate.order.totalFeePercentage(state.pair)} />

          <FormStructure.Stats.Cell>
            {props.orderKind === 'market' ? (
              <div className={style['total-text-market']}>
                <span>{t('Estimated Total')}</span>
                <span>({t('Based on the last trade')})</span>
              </div>
            ) : (
              t('Total')
            )}
          </FormStructure.Stats.Cell>
          <FormStructure.Stats.Cell className={style['total-stats-cell']}>
            {/* TODO: maybe we are able to move the "normalize" fn into "format.number" */}
            {/* "maximumFractionDigits" in "format.number" rounds the input number */}
            {format.number(normalize(state.precise.total, state.pair.priceScale))} {state.pair.quoteAsset.toUpperCase()}
          </FormStructure.Stats.Cell>
          {props.orderKind === 'market' && (
            <>
              <FormStructure.Stats.Cell>{t('Estimated Weighted Total')}</FormStructure.Stats.Cell>
              <FormStructure.Stats.Cell>
                {estimatedWeightedTotal !== null && scientificNumbersParser(estimatedWeightedTotal)}&nbsp;
                {state.pair.quoteAsset.toUpperCase()}
              </FormStructure.Stats.Cell>
            </>
          )}
        </FormStructure.Stats>

        <FormStructure.Submit
          side={props.orderSide}
          text={`${props.orderSide.toUpperCase()} ${state.pair.baseAsset.toUpperCase()}`}
          isDisabled={state.isFormInvalid || props.isDisabled}
          price={state.price}
          orderKind={props.orderKind}
          isNormalPrice={isNormalPrice}
          onMarketOrderSubmit={enableQuoteTradeOnSubmit}
        />
      </FormStructure.Root>

      <Modal.Root isOpen={marketConfirmationModalVisibility}>
        <Modal.Portal className={style['confirm-order-modal']}>
          <Modal.Header className={style['confirm-order-modal__header']}>
            <Modal.Title className={style['confirm-order-modal__title']}>{t('Confirm order')}</Modal.Title>
            <Modal.CloseButton
              className={style['confirm-order-modal__close']}
              onPress={() => setMarketConfirmationModalVisibility(false)}
            />
          </Modal.Header>

          <Modal.Content className={style['confirm-order-modal__content']}>
            <Modal.Title className={style['confirm-order-modal__title-mobile']}>{t('Confirm order')}</Modal.Title>

            <section className={style['confirm-order-modal__details']}>
              <header className={style['confirm-order-modal__details-heading']}>
                <h6 className={style['confirm-order-modal__details-title']}>{t('Review information')}</h6>
                <Timer.Root
                  className={style['confirm-order-timer']}
                  duration={MARKET_ORDER_CONFIRMATION_TIMEOUT}
                  isPlaying={isTimerPlaying}
                  onUpdate={updateDetailsOnTimerChange}
                >
                  <Timer.Indicator />
                  <Timer.Description className={style['confirm-order-timer__title']}>
                    {remaining => (
                      <>
                        {t('Quote updates in')}&nbsp;
                        <output className={style['confirm-order-timer__remaining']}>{t('%ss', remaining)}</output>
                      </>
                    )}
                  </Timer.Description>
                </Timer.Root>
              </header>

              <dl className={style['confirm-order-modal__detail-list']}>
                <dt className={style['confirm-order-modal__detail-head']}>{t('Pair')}</dt>
                <dd className={style['confirm-order-modal__detail-data']}>
                  {props.instrument.toUpperCase().replace('_', '-')}
                </dd>

                <dt className={style['confirm-order-modal__detail-head']}>{t('Side')}</dt>
                <dd className={style['confirm-order-modal__detail-data']}>
                  {format.string.toTitleCase(props.orderSide)}
                </dd>

                <dt className={style['confirm-order-modal__detail-head']}>{t('Type of order')}</dt>
                <dd className={style['confirm-order-modal__detail-data']}>
                  {format.string.toTitleCase(props.orderKind)}
                </dd>

                <dt className={style['confirm-order-modal__detail-head']}>{t('Amount')}</dt>
                <dd className={style['confirm-order-modal__detail-data']}>
                  {quoteTrade.data?.fillableAmount} {state.pair.baseAsset.toUpperCase()}
                </dd>

                <dt className={style['confirm-order-modal__detail-head']}>{t('Price')}</dt>
                <dd className={style['confirm-order-modal__detail-data']}>
                  {format.exponential(new Big(quoteTrade.data?.price || ZERO).round(state.pair.priceScale).toNumber())}
                  {state.pair.quoteAsset.toUpperCase()}
                </dd>

                <dt className={style['confirm-order-modal__detail-head']}>{t('Fee')}</dt>
                <dd className={style['confirm-order-modal__detail-data']}>
                  {calculate.order.feeValue(state.pair, quoteTrade.data, props.orderSide)}
                </dd>

                <Divider className={style['confirm-order-modal__detail-divider']} />
                <Divider className={style['confirm-order-modal__detail-divider']} />

                <dt className={style['confirm-order-modal__detail-head']}>{t('Total estimate')}</dt>
                <dd className={style['confirm-order-modal__detail-data']}>
                  {calculate.order.estimatedTotal(state.pair, quoteTrade.data, props.orderSide)}
                </dd>
              </dl>
            </section>

            <Checkbox
              text={t('Do not show this confirmation again')}
              onChange={event => useMarketOrderConfirmationSignal.setConfirmationNeeded(!event.target.checked)}
            />
          </Modal.Content>

          <Modal.Actions className={style['confirm-order-modal__actions']}>
            <Button
              className={style['confirm-order-modal__cancel']}
              type='secondary'
              size='large'
              uppercase
              onClick={() => setMarketConfirmationModalVisibility(false)}
            >
              {t('Cancel')}
            </Button>
            <Button
              className={style['confirm-order-modal__confirm']}
              htmlType='submit'
              form='order-form'
              size='large'
              uppercase
              onClick={() => setMarketConfirmationModalVisibility(false)}
            >
              {t('Confirm')}
            </Button>
          </Modal.Actions>
        </Modal.Portal>
      </Modal.Root>
    </>
  );
};

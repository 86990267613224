import { isNil } from '@ping/utils';
import { selectedMarketInstrumentSelector, useMarketStore } from '@ping/stores/market.store';

import { useMarketPair, usePortfolio } from '../helpers';
import { OrderFormStructure } from '../OrderFormStructure';
import { useStopLimitBuyFormState } from './stop-limit-buy-form-state.hook';

import type { IMarketDataType } from '@ping/websockets';

const ZERO = 0 as const;

interface IStopLimitOrderBuyFormProps {
  market: IMarketDataType;
  isDisabled?: boolean;
}

export const StopLimitOrderBuyForm = (props: IStopLimitOrderBuyFormProps) => {
  const instrument = useMarketStore(selectedMarketInstrumentSelector);
  const pair = useMarketPair(instrument);
  const { portfolio, isPortfolioFetched } = usePortfolio();
  const balance = isPortfolioFetched ? portfolio.get(pair.quoteAsset)?.availableAmount : ZERO;

  const buyState = useStopLimitBuyFormState({
    pair,
    balance,
    marketClosePrice: props.market?.close ?? ZERO,
  });

  return (
    <OrderFormStructure
      orderKind='stop-limit'
      orderSide='buy'
      instrument={instrument}
      state={buyState}
      isDisabled={props.isDisabled || isNil(balance) || balance === ZERO}
    />
  );
};

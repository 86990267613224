import { selectedMarketCurrenciesSelector, useMarketStore } from '@ping/stores/market.store';
import { CoinIcon } from '@ping/uikit';

import style from './style.module.scss';

export const SelectedMarketPair = () => {
  const pair = useMarketStore(selectedMarketCurrenciesSelector);

  return (
    <div className={style['selected-market-pair']}>
      <CoinIcon className={style['selected-market-pair__icon']} name={pair.base.toUpperCase()} />
      <strong className={style['selected-market-pair__name']}>{`${pair.base}-${pair.quote}`.toUpperCase()}</strong>
    </div>
  );
};

import React from 'react';

import type IReactAria from 'react-aria';
import type IReactStately from 'react-stately';

interface ISliderContext {
  state: IReactStately.SliderState;
  thumbIndex: number;
  labelProps: IReactAria.SliderAria['labelProps'];
  outputProps: IReactAria.SliderAria['outputProps'];
  trackRef: React.MutableRefObject<HTMLDivElement>;
  trackProps: IReactAria.SliderAria['trackProps'];
}

export const SliderContext = React.createContext<ISliderContext>({
  state: null,
  thumbIndex: 0,
  labelProps: null,
  outputProps: null,
  trackRef: null,
  trackProps: null,
});

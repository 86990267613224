export const limitDecimalNumber = (input: number | string) => {
  let resultNumber = '';

  const number = Number(input);
  if (number < 100) {
    resultNumber = number.toFixed(8);
  }
  if (number >= 100 && number < 1000) {
    resultNumber = number.toFixed(4);
  }
  if (number >= 1000) {
    resultNumber = number.toFixed(2);
  }

  return Number(resultNumber);
};

import clsx from 'clsx';
import { useContext, useRef } from 'react';

import CircleWarningIcon from '@ping/assets/Icon/circle-warning.svg';
import { t } from '@ping/helpers';
import { useMarketOrderConfirmationSignal } from '@ping/signals';
import { Button, ButtonA11Y, IModalContext, Modal, Text } from '@ping/uikit';

import { FormStructureContext } from '../form-structure.context';

import style from './style.module.scss';

interface IFormStructureSubmitProps extends ICustomizable {
  side: IOrderSide;
  text: string;
  isDisabled?: boolean;
  price: string;
  orderKind: string;
  isNormalPrice: boolean;
  onMarketOrderSubmit: () => void;
}

export const FormStructureSubmit = (props: IFormStructureSubmitProps) => {
  const context = useContext(FormStructureContext);
  const buttonRef = useRef<HTMLButtonElement>();

  const handleOnClose = (ctx: IModalContext) => {
    ctx.state.close();
  };
  const handleOpenModal = (ctx: IModalContext) => {
    if (!props.isNormalPrice && props.orderKind !== 'market') {
      ctx.state.open();
    }
    if (props.orderKind === 'market') {
      props.onMarketOrderSubmit();
    }
  };

  const handleSubmit = ctx => {
    buttonRef?.current?.click();
    ctx.state.close();
  };

  const getSubmitButtonType = ctx => {
    if (props.orderKind === 'market' && useMarketOrderConfirmationSignal.getConfirmationNeeded()) {
      return 'button';
    } else if (props.isNormalPrice) {
      return 'submit';
    } else {
      return ctx.state.isOpen ? 'submit' : 'button';
    }
  };

  return (
    <Modal.Root>
      <Modal.Context.Consumer>
        {ctx => (
          <>
            <ButtonA11Y
              className={clsx(style['form-structure-submit'], props.className)}
              data-side={props.side}
              isDisabled={props.isDisabled || context.orderState.isLoading}
              isLoading={context.orderState.isLoading}
              buttonRef={buttonRef}
              onPress={() => handleOpenModal(ctx)}
              type={getSubmitButtonType(ctx)}
            >
              {props.text}
            </ButtonA11Y>
            <Modal.Portal className={style['form-structure-submit-modal']}>
              <Modal.Header className={style['form-structure-submit-modal__header']}>
                <CircleWarningIcon className={style['form-structure-submit-modal__icon']} />
              </Modal.Header>
              <Modal.Content className={style['form-structure-submit-modal__content']}>
                <Text>
                  {t(
                    'Abnormal trading detected! Price has deviated by more than 10%. Please exercise caution and review your trading strategy.'
                  )}
                </Text>
              </Modal.Content>
              <Modal.Actions className={style['form-structure-submit-modal__actions']}>
                <Button size='large' type='secondary' uppercase onClick={() => handleOnClose(ctx)}>
                  {t('Cancel')}
                </Button>
                <Button size='large' htmlType='submit' onClick={() => handleSubmit(ctx)} uppercase>
                  {t('Execute')}
                </Button>
              </Modal.Actions>
            </Modal.Portal>
          </>
        )}
      </Modal.Context.Consumer>
    </Modal.Root>
  );
};

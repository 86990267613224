import { useEffect, useReducer, useState } from 'react';

import { WEBSOCKET_URL } from '@ping/configs';
import { t, type parsePair } from '@ping/helpers';
import { useIsUserLoggedIn } from '@ping/hooks';
import { useOrderbookPressedAmountSignal, useSelectedOrderKindSignal, useSelectedOrderSideSignal } from '@ping/signals';
import { selectedMarketInstrumentSelector, useMarketStore } from '@ping/stores/market.store';
import { isUserVerifiedSelector, useUserInformationStore } from '@ping/stores/userInformation.store';
import { Tabs, ToggleButton } from '@ping/uikit';
import { useMarketMoneyWS } from '@ping/websockets';
import { useGetInfo } from '@ping/api';

import { LimitOrderBuyForm } from './LimitOrderBuyForm';
import { LimitOrderSellForm } from './LimitOrderSellForm';
import { MarketOrderBuyForm } from './MarketOrderBuyForm';
import { MarketOrderSellForm } from './MarketOrderSellForm';
import { StopLimitOrderBuyForm } from './StopLimitOrderBuyForm';
import { useModalStore, warningMarketModalRemoteStateSelector } from '@ping/stores/modal.store';
import { StopLimitOrderSellForm } from './StopLimitOrderSellForm';
import { MarketWarningModal } from '../MarketWarningModal';
import clsx from 'clsx';

import style from './style.module.scss';

interface IOrderFormProps {
  /** @default `buy` */
  activeSide?: IOrderSide;
  pair: ReturnType<typeof parsePair.byInstrument>;
}

export const OrderForm = (props: IOrderFormProps) => {
  const [activeSide, setActiveSide] = useReducer((_, side) => {
    useSelectedOrderSideSignal.set(side);
    useOrderbookPressedAmountSignal.reset();
    return side;
  }, props.activeSide ?? 'buy');

  const instrument = useMarketStore(selectedMarketInstrumentSelector);
  const market = useMarketMoneyWS(WEBSOCKET_URL).data.get(instrument);
  const isUserLoggedIn = useIsUserLoggedIn();
  const isUserVerified = useUserInformationStore(isUserVerifiedSelector);
  const isFormStructuresDisabled = !isUserLoggedIn || !isUserVerified;
  const warningModal = useModalStore(warningMarketModalRemoteStateSelector);
  const selectedMarket = useMarketStore(selectedMarketInstrumentSelector);
  const ReadableSelectedMarket = selectedMarket.toUpperCase().replace('_', `\\`);
  const { data: marketInfo } = useGetInfo();
  const [isMarketOrderAllowed, setIsMarketOrderAllowed] = useState(true);
  const [activeKey, setActiveKey] = useState(useSelectedOrderKindSignal.get());

  const handleUpdateKey = (key: IOrderKind) => {
    setActiveKey(key);
    useSelectedOrderKindSignal.set(key);
  };

  const handleTabChange = (key: IOrderKind) => {
    if (key === 'market' && !isMarketOrderAllowed) return;
    handleUpdateKey(key);
    const uiHelperObject = JSON.parse(sessionStorage.getItem('UI_HELPER'));
    if (key === 'market' && !uiHelperObject?.marketOrderWarning) {
      warningModal.open();
    }
  };

  useEffect(() => {
    if (marketInfo) {
      setIsMarketOrderAllowed(marketInfo.pairs[selectedMarket]?.allowMarketOrder);
    }
    if (!marketInfo?.pairs[selectedMarket]?.allowMarketOrder && activeKey === 'market') {
      handleUpdateKey('limit');
    }
  }, [selectedMarket, marketInfo]);

  return (
    <>
      <div className={style['order-form']}>
        <ToggleButton.Root<IOrderSide>
          compact
          label={t('Select buy/sell type')}
          isDisabled={isFormStructuresDisabled}
          defaultValue={activeSide}
          onChange={setActiveSide}
        >
          <ToggleButton.Choice value='buy' aria-label={t('Buy %s', props.pair.base)}>
            {t('Buy %s', props.pair.base)}
          </ToggleButton.Choice>
          <ToggleButton.Choice value='sell' aria-label={t('Sell %s', props.pair.base)}>
            {t('Sell %s', props.pair.base)}
          </ToggleButton.Choice>
        </ToggleButton.Root>

        <Tabs.Root
          className={style['trading-widget-tabs']}
          variant='secondary'
          selectedKey={activeKey}
          isEven
          isDisabled={isFormStructuresDisabled}
          disabledKeys={!isMarketOrderAllowed && ['market']}
          onSelectionChange={handleTabChange}
          tabsHeaderClassName={style['trading-tabs-header']}
        >
          <Tabs.Item key='limit' title={t('Limit')}>
            {activeSide === 'buy' && <LimitOrderBuyForm market={market} isDisabled={isFormStructuresDisabled} />}
            {activeSide === 'sell' && <LimitOrderSellForm market={market} isDisabled={isFormStructuresDisabled} />}
          </Tabs.Item>

          <Tabs.Item
            key='market'
            title={t('Market')}
            className={clsx({ [style['trading-widget-tabs__disabled']]: !isMarketOrderAllowed })}
            disabled={!isMarketOrderAllowed}
            disabledMessage={t('market order for the %s is not available', ReadableSelectedMarket)}
          >
            {activeSide === 'buy' && <MarketOrderBuyForm market={market} isDisabled={isFormStructuresDisabled} />}
            {activeSide === 'sell' && <MarketOrderSellForm market={market} isDisabled={isFormStructuresDisabled} />}
          </Tabs.Item>

          <Tabs.Item key='stop-limit' title={t('Stop Limit')}>
            {activeSide === 'buy' && <StopLimitOrderBuyForm market={market} isDisabled={isFormStructuresDisabled} />}
            {activeSide === 'sell' && <StopLimitOrderSellForm market={market} isDisabled={isFormStructuresDisabled} />}
          </Tabs.Item>
        </Tabs.Root>
      </div>
      <MarketWarningModal />
    </>
  );
};

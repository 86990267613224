import { OrderInfoResponse } from '@ping/api';
import CloseIcon from '@ping/assets/Icon/close.svg';
import { t } from '@ping/helpers';
import getOrdersColumnsList from '@ping/helpers/get-orders-columns-list';
import { Spinner } from '@ping/uikit';
import { useState } from 'react';

import style from './style.module.scss';

interface IColumnsList {
  openOrders: OrderInfoResponse[];
  deleteOrderVariables: {
    orderId: string;
  };
  isDeleteOrderLoading: boolean;
  isDeleteAllOrdersLoading: boolean;
  handleDeleteOrder: (id: string) => void;
  handleDeleteAllOrders: () => void;
  isSortedByUpdatedAt?: boolean;
}

export default function getColumnsList({
  openOrders,
  deleteOrderVariables,
  isDeleteOrderLoading,
  isDeleteAllOrdersLoading,
  handleDeleteOrder,
  handleDeleteAllOrders,
  isSortedByUpdatedAt,
}: IColumnsList) {
  const [deletedOrderId, setDeletedOrderId] = useState([]);

  return getOrdersColumnsList(
    {
      Header: isDeleteAllOrdersLoading ? (
        <Spinner size={8} />
      ) : openOrders?.length ? (
        <div className={style['cancel-all-btn']} onClick={handleDeleteAllOrders}>
          Cancel all
        </div>
      ) : (
        'Cancel all'
      ),
      columnTextAlignment: 'center',
      accessor: t('cancelAll'),
      disableColumnSorting: true,
      Cell: ({ row: { original } }: { row: { original: OrderInfoResponse } }) =>
        isDeleteOrderLoading && deletedOrderId.includes(original.orderId) ? (
          <Spinner size={8} />
        ) : (
          <div
            className={style['cancel-button']}
            onClick={() => {
              setDeletedOrderId(prevState => [...prevState, original.orderId]);
              handleDeleteOrder(original.orderId);
            }}
          >
            <CloseIcon className={style['cancel-icon']} />
          </div>
        ),
    },
    [deletedOrderId, isDeleteOrderLoading, isDeleteAllOrdersLoading, deleteOrderVariables, openOrders],
    isSortedByUpdatedAt
  );
}

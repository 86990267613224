import { useEffect, useState } from 'react';
import { selectIsMarketConnected, selectIsNotificationsConnected, useSocketsStore } from '@ping/stores/sockets.store';
import { t } from '@ping/helpers';
import BarChartIcon from '@ping/assets/Icon/bar-chart.svg';
import { useClientEffect, useIsUserLoggedIn, useToggle } from '@ping/hooks';
import clsx from 'clsx';
import style from './style.module.scss';
import { Tooltip } from '@ping/uikit/TooltipA11Y';
import { domEvent } from '@ping/utils';

export const SocketConnectivityIndicator = () => {
  const isUserLoggedIn = useIsUserLoggedIn();
  const isMarketConnected = useSocketsStore(selectIsMarketConnected);
  const isNotificationsConnectedIgnoringUserLoggedInState = useSocketsStore(selectIsNotificationsConnected);
  const isNotificationsConnected = useSocketsStore(selectIsNotificationsConnected) || !isUserLoggedIn;
  const [isOnline, setIsOnline] = useState(true);
  const [inspectVisibility, toggleInspectVisibility] = useToggle(false);

  const isStableConnection = isMarketConnected && isNotificationsConnected && isOnline;
  const isPartialConnection = (isMarketConnected || isNotificationsConnected) && isOnline;
  const isNoConnection = (!isMarketConnected && !isNotificationsConnected) || !isOnline;

  const handleOnlineStatusChange = () => {
    setIsOnline(true);
  };

  const handleOfflineStatusChange = () => {
    setIsOnline(false);
  };

  useEffect(() => {
    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOfflineStatusChange);

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOfflineStatusChange);
    };
  }, []);

  useClientEffect(() => {
    return domEvent.attach(window, 'keydown', event => {
      if (event.ctrlKey && event.shiftKey && event.key === 'I') {
        toggleInspectVisibility();
      }
    });
  }, []);

  const getTitle = () => {
    if (isStableConnection) {
      return t('Stable connection');
    }
    if (isPartialConnection) {
      return t('Partial connection');
    }
    return t('No connection');
  };

  const handleClick = () => {
    if (!isMarketConnected && !isNotificationsConnected) {
      window.location.reload();
    }
  };

  return (
    <Tooltip.Root isOpen={inspectVisibility}>
      <Tooltip.Trigger>
        <span
          className={clsx(style['socket-connectivity-status'], {
            [style['is-stable']]: isStableConnection,
            [style['is-partial']]: isPartialConnection,
            [style['is-disconnected']]: isNoConnection,
          })}
          onClick={handleClick}
        >
          <BarChartIcon className={style['socket-connectivity-status--icon']} />
          <span className={style['socket-connectivity-status--title']}>{getTitle()}</span>
        </span>
      </Tooltip.Trigger>

      <Tooltip.Content className={style['inspect']}>
        <h6>
          Inspect:&nbsp;
          <small>
            <code>(Ctrl + Shift + I)</code>
          </small>
        </h6>
        <p data-error={!isNoConnection}>No Connection: {String(isNoConnection)}</p>
        <p data-error={isPartialConnection}>Partial Connection: {String(isPartialConnection)}</p>
        <p data-error={isStableConnection}>Stable Connection: {String(isStableConnection)}</p>
        <br />
        <p data-error={isMarketConnected}>Market connected: {String(isMarketConnected)}</p>
        <p data-error={isNotificationsConnectedIgnoringUserLoggedInState}>
          Notification connected: {String(isNotificationsConnectedIgnoringUserLoggedInState)}
        </p>
        <br />
        <p data-error={isOnline}>User is online: {String(isOnline)}</p>
        <p data-error={isUserLoggedIn}>User is logged in: {String(isUserLoggedIn)}</p>
      </Tooltip.Content>
    </Tooltip.Root>
  );
};

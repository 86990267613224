import { useEffect, useState } from 'react';
import { Big } from 'big.js';
import { t } from '@ping/helpers';
import clsx from 'clsx';
import { scientificNumbersParser } from '@ping/helpers';
import styles from './style.module.scss';

interface IAggregateControllerProps {
  defaultNumber?: number;
  minNumber?: number;
  maxNumber?: number;
  className?: string;
  onChange?: (selectedNumber: number) => void;
  value?: number;
}

type TNumberChangeType = 'increase' | 'decrease';

export const AggregateController: React.FC<IAggregateControllerProps> = props => {
  const { defaultNumber = 0.01, minNumber = 0.0001, maxNumber = 1, className = '', onChange } = props;
  const [selectedNumber, setSelectedNumber] = useState<number>(defaultNumber);

  const handleSelectedNumberChange = (type: TNumberChangeType) => {
    const nextSelectedNumber = getNextSelectedValue(type);
    setSelectedNumber(nextSelectedNumber);
    onChange?.(nextSelectedNumber);
  };

  const getNextSelectedValue = (type: TNumberChangeType) => {
    if (type === 'increase' && selectedNumber < maxNumber) {
      return new Big(selectedNumber).times(10).toNumber();
    }

    if (type === 'decrease' && selectedNumber > minNumber) {
      return new Big(selectedNumber).div(10).toNumber();
    }
  };

  useEffect(() => {
    if (props.value) {
      setSelectedNumber(props.value);
    }
  }, [props.value]);

  return (
    <div className={clsx(styles['aggregate-controller'], className)}>
      <span>{t('Aggregation')}</span>
      <span>{scientificNumbersParser(selectedNumber)}</span>
      <div className={styles['aggregate-controller__btn-container']}>
        <button
          className={styles['aggregate-controller__decrease']}
          onClick={() => handleSelectedNumberChange('decrease')}
          disabled={selectedNumber <= minNumber}
        >
          -
        </button>
        <button
          className={styles['aggregate-controller__increase']}
          onClick={() => handleSelectedNumberChange('increase')}
          disabled={selectedNumber >= maxNumber}
        >
          +
        </button>
      </div>
    </div>
  );
};

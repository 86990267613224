import Big from 'big.js';

import { type MarketInfo } from '@ping/api';
import { t } from '@ping/helpers';
import { Spinner } from '@ping/uikit';
import { format, normalizeRoundDown } from '@ping/utils';

import { type IOrderFormState } from '../../../helpers';

import style from './style.module.scss';

interface IFormStructureBalanceProps {
  balance: IOrderFormState['balance'];
  pair: MarketInfo;
  orderSide: IOrderSide;
}

const SIDE_TO_SCALE = {
  buy: 'amountScale',
  sell: 'priceScale',
} as const;

export const FormStructureBalance = ({ balance, orderSide, pair }: IFormStructureBalanceProps) => {
  const maxLabel = t('Max %s: ', format.string.toTitleCase(orderSide));
  const assetScaleKey = SIDE_TO_SCALE[orderSide];
  const maxValue = format.number(normalizeRoundDown(balance.equivalent.value, pair[assetScaleKey]).toString());
  const availableBalanceScale = assetScaleKey === 'amountScale' ? pair.priceScale : pair.amountScale;
  const formattedBalance =
    Number(balance.value) != 0
      ? format.number(normalizeRoundDown(new Big(balance.value), availableBalanceScale).toString())
      : 0;

  return (
    <>
      {balance.isBalanceLoading ? (
        <Spinner isLoading size={8} />
      ) : (
        <p className={style['trading-widget-form-balance']}>
          {t('Available Balance')}:&nbsp;{formattedBalance}&nbsp;{balance.currency}&nbsp; ({maxLabel}
          {maxValue}&nbsp;{balance.equivalent.currency})
        </p>
      )}
    </>
  );
};

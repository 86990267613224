import { omit } from 'rambdax';
import { useEffect, useRef } from 'react';
import { useOverlayPosition, useOverlayTrigger } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';

import { PopoverContext } from './popover.context';
import PopoverPanel from './PopoverPanel';
import PopoverTrigger from './PopoverTrigger';

import type IReactAria from 'react-aria';
import type IReactStately from 'react-stately';

interface IPopoverProps extends IReactAria.OverlayTriggerProps {
  boundaryRef?: React.MutableRefObject<HTMLElement>;
  triggerRef?: React.MutableRefObject<HTMLButtonElement>;
  isOpen?: boolean;
  children: React.ReactNode;
  state?: IReactStately.OverlayTriggerState;
  offset?: number;
  /**
   * The placement of the element with respect to its trigger element.
   * @default 'bottom'
   */
  placement?: IReactAria.AriaPositionProps['placement'];
  onPlacementChange?: (placement: IReactAria.AriaPositionProps['placement']) => void;
}

export const Popover = (props: IPopoverProps) => {
  const state = props.state ?? useOverlayTriggerState({});

  const triggerRef = props.triggerRef ?? useRef<HTMLButtonElement>();
  const overlayRef = useRef<HTMLDivElement>();

  // Get props for the trigger and overlay
  // NOTE: it hides the overlay when a parent element of the trigger scrolls (which invalidates the popover positioning).
  const { triggerProps, overlayProps } = useOverlayTrigger(omit('placement', props), state, triggerRef);

  // Get popover positioning props relative to the trigger
  const { overlayProps: positionProps, placement } = useOverlayPosition({
    targetRef: props.boundaryRef || triggerRef,
    overlayRef,
    placement: props.placement || 'bottom',
    isOpen: state.isOpen,
    shouldUpdatePosition: true,
    offset: props.offset ?? 5,
  });

  useEffect(() => {
    props.onPlacementChange?.(placement as IReactAria.AriaPositionProps['placement']);
  }, [placement, positionProps]);

  const triggerSubComponentProps = { triggerRef, triggerProps, placement };
  const panelSubComponentProps = {
    triggerRef,
    boundaryRef: props.boundaryRef || triggerRef,
    overlayProps,
    positionProps,
    overlayRef,
    state,
    placement,
  };

  return (
    <PopoverContext.Provider value={{ triggerSubComponentProps, panelSubComponentProps }}>
      {props.children}
    </PopoverContext.Provider>
  );
};

Popover.Trigger = PopoverTrigger;
Popover.Panel = PopoverPanel;

export default Popover;

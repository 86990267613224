import { useCallback } from 'react';

import { type OrderSide } from '@ping/api';
import { EOrderSide } from '@ping/enums';
import { Store } from '@ping/utils';

interface ISelectedOrderSideSignal {
  value: Lowercase<OrderSide>;
}

// --------------------------------------------------------------------------------
// SELECTED ORDER SIDE SIGNAL
// --------------------------------------------------------------------------------
const selectedOrderSideSignal = new Store<ISelectedOrderSideSignal>({ value: EOrderSide.BUY.toLowerCase() })
  .withDevTools()
  .build();

// --------------------------------------------------------------------------------
// SELECTED ORDER SIDE HOOK
// --------------------------------------------------------------------------------
export const useSelectedOrderSideSignal = () => {
  const value = selectedOrderSideSignal(state => state.value);
  const set = useCallback((input: Lowercase<OrderSide>) => selectedOrderSideSignal.setState({ value: input }), []);

  return Object.freeze({ value, set });
};

// --------------------------------------------------------------------------------
// SELECTED ORDER SIDE NON-REACT
// --------------------------------------------------------------------------------
useSelectedOrderSideSignal.get = () => selectedOrderSideSignal.getState().value;
useSelectedOrderSideSignal.set = (input: Lowercase<OrderSide>) => selectedOrderSideSignal.setState({ value: input });

import { useUserSettingsGet } from '@ping/api';
import { generateMarketPairsData, groupMarkets } from '@ping/helpers';
import { useIsUserLoggedIn } from '@ping/hooks';
import { marketDataStore } from '@ping/stores/marketData.store';

export const getPairs = () => {
  const isUserLoggedIn = useIsUserLoggedIn();

  const { data: userSetting } = useUserSettingsGet({
    query: {
      enabled: isUserLoggedIn,
      staleTime: Infinity, // will be invalidated when the user selects/deselects a favorite market
    },
  });
  const market = marketDataStore.getMarketData();

  return groupMarkets.byAssetName(generateMarketPairsData([...market.values()], userSetting?.data?.favoriteMarkets));
};

import clsx from 'clsx';
import Big from 'big.js';
import {
  CancelReason,
  getGetPortfolioQueryKey,
  getMyTradeQueryKey,
  getOrderHistoryTradeQueryKey,
  getSwapTradeQueryKey,
  OrderInfoRequest,
  useCreateTrade,
  useQuoteTrade,
} from '@ping/api';
import { DEFAULT_CURRENCY, DEFAULT_MARKET_QUOTE } from '@ping/configs';
import { EOrderStatus } from '@ping/enums';
import { t } from '@ping/helpers';
import * as gtm from '@ping/helpers/gtm.helper';
import { useSelectedCurrency } from '@ping/hooks';
import { queryClient } from '@ping/providers/ReactQueryProvider';
import { Toast } from '@ping/uikit';
import { domEvent } from '@ping/utils';
import { useMarketStore, selectedMarketInstrumentSelector } from '@ping/stores/market.store';
import { useMarketPair } from '../../../helpers';
import { FormStructureContext } from '../form-structure.context';

import style from './style.module.scss';
import { useRef, useState } from 'react';

interface IFormStructureProps extends ICustomizable {
  onSuccess?: () => void;
  children: React.ReactNode;
  marketPrice?: number;
  id?: string;
}

type TOrder = OrderInfoRequest & { kind: 'limit' | 'market' | 'stop-limit' };

export const FormStructureRoot = (props: IFormStructureProps) => {
  const { mutate: createOrder, ...orderState } = useCreateTrade();
  const selectedCurrency = useSelectedCurrency();
  const instrument = useMarketStore(selectedMarketInstrumentSelector);
  const pair = useMarketPair(instrument);
  const [order, setOrder] = useState<TOrder>(null);
  const formRef = useRef<HTMLFormElement>(null);

  useQuoteTrade(
    { Amount: order?.amount, Instrument: instrument, IsBuy: order?.type === 'buy' },
    {
      query: {
        enabled: order?.kind === 'market',
        onSuccess: quoteData => {
          tradeHandler({ ...order, price: new Big(quoteData.price).round(pair.priceScale).toNumber() });
        },
      },
    }
  );

  const handleOnSubmitOrder = async (event: React.FormEvent<HTMLFormElement>) => {
    domEvent.cancel(event);

    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);
    formRef.current = form;
    const orderValues = Object.fromEntries(formData.entries());

    let orderKind;
    if (String(orderValues.isLimit) === 'true') {
      orderKind = 'limit';
    } else if (String(orderValues.isStop) === 'true') {
      orderKind = 'stop-limit';
    } else {
      orderKind = 'market';
    }

    setOrder({ ...orderValues, kind: orderKind });

    gtm.orderTypeEvent(orderKind, orderValues.type);

    if (orderKind !== 'market') {
      tradeHandler(orderValues);
    }
  };

  const tradeHandler = order => {
    createOrder(
      { data: { order } },
      {
        onSuccess: result => {
          queryClient.invalidateQueries(getMyTradeQueryKey());
          queryClient.invalidateQueries(getOrderHistoryTradeQueryKey());
          queryClient.invalidateQueries(getGetPortfolioQueryKey());
          queryClient.invalidateQueries(
            getSwapTradeQueryKey({
              FromAssetId: DEFAULT_MARKET_QUOTE.toLowerCase(),
              ToAssetId: selectedCurrency.toLowerCase(),
              Amount: 1,
              DefaultCurrency: DEFAULT_CURRENCY.toLowerCase(),
            })
          );

          if (result.order.status !== EOrderStatus.CANCELLED) {
            Toast.success({ title: t('Order created successfully') });
            formRef.current.reset();
            props.onSuccess();
          } else {
            if (result.order.cancelReason === CancelReason.NoMoreMatchesAvailable) {
              Toast.error({ title: t('Order has been cancelled because there are no more matches available.') });
            } else {
              Toast.error({ title: t('Order has been cancelled because of self match') });
            }
          }
        },
        onSettled: () => {
          setOrder(null);
          formRef.current = null;
        },
      }
    );
  };

  return (
    <form
      className={clsx(style['form-structure'], props.className)}
      data-status={orderState.status}
      id={props.id}
      onSubmit={handleOnSubmitOrder}
    >
      <FormStructureContext.Provider value={{ orderState }}>{props.children}</FormStructureContext.Provider>
    </form>
  );
};

import React, { useContext } from 'react';

import style from './style.module.scss';

import { SliderContext } from '../slider.context';

interface ISliderTrackProps extends ICustomizable {
  children: React.ReactNode;
}

const ZERO = 0 as const;
const TWO_DIGITS = 2 as const;

export const SliderTrack = (props: ISliderTrackProps) => {
  const { thumbIndex, state, trackRef, trackProps } = useContext(SliderContext);

  const percentage = state.getValuePercent(state.values[thumbIndex]) || ZERO;
  trackRef.current?.style.setProperty('--filled-segments-percentage', percentage.toFixed(TWO_DIGITS));

  return (
    <div {...trackProps} className={style['slider-track']} ref={trackRef}>
      {props.children}
    </div>
  );
};

import { noop } from 'rambdax';
import React from 'react';

interface ITimerContext {
  duration: number;
  isPlaying: boolean;
  remaining: number;
  setRemainingTime: (number) => void;
}

export const TimerContext = React.createContext<ITimerContext>({
  duration: 0,
  isPlaying: false,
  remaining: 0,
  setRemainingTime: noop,
});

import { FormStructureBalance } from './FormStructureBalance';
import { FormStructureDivider } from './FormStructureDivider';
import { FormStructureFeeRow } from './FormStructureFeeRow';
import { FormStructureGroup } from './FormStructureGroup';
import { FormStructureRoot } from './FormStructureRoot';
import { FormStructureStats } from './FormStructureStats';
import { FormStructureSubmit } from './FormStructureSubmit';

export const FormStructure = {
  Root: FormStructureRoot,
  Balance: FormStructureBalance,
  FeeRow: FormStructureFeeRow,
  Divider: FormStructureDivider,
  Group: FormStructureGroup,
  Stats: FormStructureStats,
  Submit: FormStructureSubmit,
};

import { findSelectedMarketDetail, t } from '@ping/helpers';
import { selectedMarketSelector, useMarketStore } from '@ping/stores/market.store';
import { getPairs } from '@ping/hooks';

import { roundPercentage } from '@ping/utils';

const ZERO = 0 as const;
const DELTA_PERCENTAGE = 0.1 as const;

/**
 * Determines if a given price is within a normal range of a market's last price.
 * @param {string} price - The `price` parameter is a string representing the input price.
 */
export const useIsNormalPriceComparison = (price: string) => {
  const inputPrice = parseFloat(price);

  const selectedMarket = useMarketStore(selectedMarketSelector);
  const selectedMarketDetail = findSelectedMarketDetail(selectedMarket, getPairs());
  const marketLastPrice = selectedMarketDetail?.close;

  if (!(inputPrice >= ZERO && marketLastPrice > ZERO)) {
    return Object.freeze({
      isNormalPrice: true,
      percentage: { value: '0', range: '' },
    });
  }

  const change = inputPrice - marketLastPrice;

  return Object.freeze({
    isNormalPrice: Math.abs(change) < marketLastPrice * DELTA_PERCENTAGE,
    percentage: {
      value: roundPercentage((Math.abs(change) / marketLastPrice) * 100),
      range: change > ZERO ? t('higher') : t('lower'),
    },
  });
};

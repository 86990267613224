import { useState, useEffect } from 'react';
import Big from 'big.js';
import { calculateEstimatedWeightedTotal } from '@ping/helpers';
import { normalize } from '@ping/utils';
import { useOrderBookWS } from '@ping/websockets';
import { EOrderSide } from '@ping/enums';

export const useEstimatedWeightedTotal = (assetAmount, tradeType, currencyScale) => {
  const books = useOrderBookWS();
  const [amount, setAmount] = useState(null);

  useEffect(() => {
    const estimatedWeightedTotalAmount = () => {
      let estimatedWeightedTotalResult;
      const orderBookData = tradeType.toLowerCase() === EOrderSide.BUY.toLowerCase() ? books?.asks : books?.bids;
      const estimatedTotal = calculateEstimatedWeightedTotal(orderBookData || []);

      if (Big(estimatedTotal).gt(0)) {
        estimatedWeightedTotalResult = assetAmount.mul(Big(estimatedTotal));

        if (!isNaN(estimatedWeightedTotalResult) && estimatedWeightedTotalResult !== Infinity) {
          const normalizedAmount = normalize(estimatedWeightedTotalResult, currencyScale);
          setAmount(normalizedAmount.toString());
        } else {
          setAmount('0');
        }
      } else {
        setAmount(null);
      }
    };

    estimatedWeightedTotalAmount();
  }, [assetAmount, books, tradeType, currencyScale]);

  return amount;
};

import { ChangeEvent, Dispatch, SetStateAction } from 'react';

export const handleSearch = (
  event: ChangeEvent<HTMLInputElement>,
  setFilterQuery: Dispatch<SetStateAction<string>>
) => {
  event.currentTarget.value = event.currentTarget.value.trim();

  setFilterQuery(event.currentTarget.value.replace('/', '').toUpperCase());
};

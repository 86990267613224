import { WEBSOCKET_URL } from '@ping/configs';
import { Loading } from '@ping/uikit';
import { useMarketMoneyWS } from '@ping/websockets';
import { MarketOrderForm } from './MarketOrderForm';
import { MarketOrderPair } from './MarketOrderPair';
import style from './style.module.scss';

export const MarketOrder = () => {
  const market = useMarketMoneyWS(WEBSOCKET_URL);

  if (market.isLoading) {
    return (
      <div className={style['market-order']}>
        <div className={style['market-order__loading']} aria-hidden={!market.isLoading}>
          <Loading className={style['market-order__loading-indicator']} />
        </div>
      </div>
    );
  }

  return (
    <div className={style['market-order']}>
      <MarketOrderPair />
      <MarketOrderForm />
    </div>
  );
};

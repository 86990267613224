import { useEffect, useRef, useCallback } from 'react';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';

interface IUseInfiniteLoading<T> {
  fetchNextPage: (options?: FetchNextPageOptions) => Promise<InfiniteQueryObserverResult<T, unknown>>;
  hasNextPage: boolean;
}

export const useInfiniteLoading = <T>({ fetchNextPage, hasNextPage }: IUseInfiniteLoading<T>) => {
  const observerElement = useRef<HTMLDivElement>(null);

  const handleObserver = useCallback(
    entries => {
      const [target] = entries;
      if (target.isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage]
  );

  useEffect(() => {
    const element = observerElement.current;
    const option = { threshold: 0 };

    const observer = new IntersectionObserver(handleObserver, option);

    if (element) {
      observer?.observe(element);
      return () => observer.unobserve(element);
    }
  }, [fetchNextPage, hasNextPage, handleObserver]);

  return { observerElement };
};

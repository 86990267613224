import clsx from 'clsx';
import { omit } from 'rambdax';
import { useEffect, useState } from 'react';

import { TimerContext } from '../timer.context';

import style from './style.module.scss';

interface ITimerRootProps extends ICustomizable {
  duration: number;
  isPlaying: boolean;
  children: React.ReactNode;
  onUpdate?: (remaining: number) => ReturnType<React.EffectCallback>;
}

export const TimerRoot = (props: ITimerRootProps) => {
  const [remaining, setRemainingTime] = useState(0);

  useEffect(() => props.onUpdate?.(remaining), [remaining]);

  return (
    <div className={clsx(style['timer-root'], props.className)} role='timer' aria-live='assertive'>
      <TimerContext.Provider value={{ ...omit(['children'], props), remaining, setRemainingTime }}>
        {props.children}
      </TimerContext.Provider>
    </div>
  );
};

import { format } from '@ping/utils';

/**
 * Parse scientific numbers and return a formatted string of the number with a specified precision.
 *
 * @deprecated use `format.exponential()`
 * @param {number} input - the number to be parsed
 * @param {number} [precision] - the precision for formatting the number
 * @return {string} the formatted number string
 */
export const scientificNumbersParser = (input: number, precision?: number) => {
  return format.exponential(input, precision);
};

import { ApiMarketStatus, MarketInfo, useGetInfo } from '@ping/api';

const ONE_DAY = 24 * 60 * 60 * 1_000;

const PAIR_PLACEHOLDER: MarketInfo = {
  amountScale: 0,
  baseAsset: '-',
  baseFee: 0,
  hidden: false,
  id: '-',
  makerFee: 0,
  makerFeeLimit: 0,
  maxPrice: 0,
  minAmount: 0,
  minPrice: 0,
  priceScale: 0,
  quoteAsset: '-',
  quoteFee: 0,
  status: ApiMarketStatus.Open,
  takerFee: 0,
  takerFeeLimit: 0,
};

export const useMarketPair = (instrument: string) => {
  const pairs = useGetInfo({
    query: {
      staleTime: ONE_DAY,
      select: ({ pairs }) => {
        return new Map(Object.entries(pairs));
      },
    },
  });

  return !pairs.isLoading && pairs.data ? pairs.data.get(instrument) : PAIR_PLACEHOLDER;
};

import { Big } from 'big.js';
import { useEffect } from 'react';

import { normalize, normalizeRoundDown } from '@ping/utils';
import { subscribeOrderBookPressedPriceValue } from '@ping/stores/orderBookPressedPrice.effects';
import { useOrderFormCommonState, validate } from '../helpers';

import type { IOrderFormStateParams } from '../helpers';

const ZERO = 0 as const;

export const useLimitBuyFormState = (params: IOrderFormStateParams) => {
  const state = useOrderFormCommonState('limit buy', params, $state => {
    //
    // Precise values
    $state.precise.price = Big($state.price || ZERO);
    $state.precise.amount = Big($state.amount || ZERO);
    $state.precise.marketClosePrice = Big($state.marketClosePrice || ZERO);
    $state.precise.balance = Big($state.balance.value || ZERO);
    $state.precise.total = $state.precise.amount.mul($state.precise.price);
    //
    // Max values
    $state.max.price = Big(Number.MAX_SAFE_INTEGER);
    $state.max.amount =
      !$state.precise.price.eq(ZERO) && !$state.precise.balance.eq(ZERO)
        ? $state.precise.balance.div($state.precise.price)
        : Big(Number.MAX_SAFE_INTEGER);
    //
    // Form status
    $state.isMaxButtonVisible =
      !$state.precise.balance.eq(ZERO) &&
      $state.precise.price.gt(ZERO) &&
      !$state.precise.amount.eq(normalize($state.max.amount, params.pair.amountScale));
    $state.isAmountSliderDisabled = $state.precise.price.eq(ZERO);
    //
    // Error messages
    $state.error.price = validate.price(
      params.pair.minPrice,
      $state.max.price,
      $state.precise.price,
      params.pair.priceScale
    );
    $state.error.amount = validate.amount(
      params.pair.minAmount,
      $state.max.amount,
      $state.precise.amount,
      params.pair.amountScale
    );
    //
    // Balance info
    $state.balance.currency = params.pair.quoteAsset.toUpperCase();
    $state.balance.equivalent.currency = params.pair.baseAsset.toUpperCase();
    $state.balance.equivalent.value =
      !$state.precise.marketClosePrice.eq(0) && !$state.precise.balance.eq(0)
        ? $state.precise.balance.div($state.precise.marketClosePrice).toFixed()
        : ZERO.toString();
    //
    // Normalize values
    $state.max.price = normalize($state.max.price, params.pair.priceScale);
    $state.max.amount = normalizeRoundDown($state.max.amount, params.pair.amountScale);

    return $state;
  });

  //
  // Prefilled the price with pressed value at the orderbook
  useEffect(() => subscribeOrderBookPressedPriceValue(state.setPrice).unsubscribe, []);

  return state;
};

import { createContext } from 'react';

import type IReactAria from 'react-aria';
import type IReactStately from 'react-stately';

interface IPopoverContext {
  triggerSubComponentProps: {
    triggerRef: React.MutableRefObject<HTMLButtonElement>;
    triggerProps: IReactAria.AriaButtonProps<'button'>;
    placement: 'top' | 'bottom' | 'left' | 'right' | 'center';
  };

  panelSubComponentProps: {
    boundaryRef: React.MutableRefObject<HTMLElement>;
    overlayProps: IReactAria.OverlayTriggerAria['overlayProps'];
    positionProps: React.DOMAttributes<HTMLElement>;
    overlayRef: React.MutableRefObject<HTMLDivElement>;
    state: IReactStately.OverlayTriggerState;
    placement: 'top' | 'bottom' | 'left' | 'right' | 'center';
  };
}

export const PopoverContext = createContext<IPopoverContext>(null);

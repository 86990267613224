import { ScrollableTab } from '@ping/uikit';
import { findSelectedMarketDetail } from '@ping/helpers';
import { getPairs, useDesktopDevice, useIsUserLoggedIn, useMobile, useTabletDevice } from '@ping/hooks';
import { marketPairsModalRemoteStateSelector, useModalStore } from '@ping/stores/modal.store';
import { useUpdateMarketPairs } from './helpers';
import { MarketPairsDropdownTrigger } from './MarketPairsDropdownTrigger';
import { SelectedMarketPairState } from './SelectedMarketPairState';
import { MarketPairs } from './MarketPairs';
import { MarketPairsModal } from './MarketPairsModal';
import { SelectedMarketPair } from '../SelectedMarketPair';

import FavStar from '@ping/assets/Icon/favIcon.svg';
import CaretDownIcon from '@ping/assets/Icon/caret-down.svg';

import style from './style.module.scss';

interface IMarketOrderPairProps {
  onMarketPairsModalOpenChange?: (isOpen: boolean) => void;
  onSetShowMarket?: (market) => void;
}

export const MarketOrderPair = ({ onMarketPairsModalOpenChange, onSetShowMarket }: IMarketOrderPairProps) => {
  const isUserLoggedIn = useIsUserLoggedIn();

  const marketPairsModal = useModalStore(marketPairsModalRemoteStateSelector);
  const pairs = getPairs();

  const {
    showMarket,
    setShowMarket,
    setFilterQuery,
    selectedMarket,
    filterQuery,
    filterPairs,
    visiblePairs,
    coinTabOptions,
    handleSearch,
  } = useUpdateMarketPairs({
    pairs,
    icon: <FavStar />,
    showFav: isUserLoggedIn,
  });
  const selectedMarketDetail = findSelectedMarketDetail(selectedMarket, pairs);
  const isMobile = useMobile();
  const isTablet = useTabletDevice();
  const isDesktop = useDesktopDevice();

  return (
    <>
      <div className={style['market-order-pair']}>
        <div className={style['market-order-pair__assets']}>
          <SelectedMarketPair />
          {isDesktop && (
            <MarketPairsDropdownTrigger>
              <MarketPairs
                visiblePairs={visiblePairs}
                filterQuery={filterQuery}
                handleSearch={handleSearch}
                filterPairs={filterPairs}
                showMarket={showMarket}
                setShowMarket={setShowMarket}
                setFilterQuery={setFilterQuery}
                coinTabOptions={coinTabOptions}
                selectedMarket={selectedMarket}
              />
            </MarketPairsDropdownTrigger>
          )}
          {isTablet && (
            <div className={style['market-order-pair__trigger']}>
              <CaretDownIcon
                onClick={() => {
                  marketPairsModal.open();
                  onMarketPairsModalOpenChange?.(true);
                }}
                className={style['market-order-pair__trigger-icon']}
              />
            </div>
          )}
        </div>

        <SelectedMarketPairState market={selectedMarketDetail} />

        {isMobile && (
          <>
            <ScrollableTab
              options={coinTabOptions}
              value={showMarket}
              onChange={market => {
                setShowMarket(market as string);
                onSetShowMarket?.(market as string);
                marketPairsModal.open();
              }}
            />
            <MarketPairsModal>
              <MarketPairs
                visiblePairs={visiblePairs}
                filterQuery={filterQuery}
                handleSearch={handleSearch}
                filterPairs={filterPairs}
                showMarket={showMarket}
                setShowMarket={setShowMarket}
                setFilterQuery={setFilterQuery}
                coinTabOptions={coinTabOptions}
                selectedMarket={selectedMarket}
              />
            </MarketPairsModal>
          </>
        )}
      </div>
    </>
  );
};

import { Big } from 'big.js';
import { useEffect } from 'react';

import { scientificNumbersParser } from '@ping/helpers';
import { useOrderbookPressedAmountSignal } from '@ping/signals';
import { normalize, normalizeRoundDown } from '@ping/utils';

import { useOrderFormCommonState, validate } from '../helpers';

import type { IOrderFormStateParams } from '../helpers';

const ZERO = 0 as const;

export const useMarketSellFormState = (params: IOrderFormStateParams) => {
  const state = useOrderFormCommonState('market sell', params, $state => {
    //
    // Precise values
    $state.precise.price = Big($state.price || ZERO);
    $state.precise.amount = Big($state.amount || ZERO);
    $state.precise.marketClosePrice = Big($state.marketClosePrice || ZERO);
    $state.precise.balance = Big($state.balance.value || ZERO);
    $state.precise.total = $state.precise.amount.mul($state.precise.price);
    //
    // Max values
    ($state.max.amount = $state.precise.balance), params.pair.amountScale;
    //
    // Form status
    $state.isMaxButtonVisible =
      !$state.precise.balance.eq(ZERO) &&
      !$state.precise.amount.eq(normalize($state.max.amount, params.pair.amountScale));
    $state.isAmountSliderDisabled = $state.precise.balance.eq(ZERO);
    //
    // Error messages
    $state.error.amount = validate.amount(
      params.pair.minAmount,
      $state.max.amount,
      $state.precise.amount,
      params.pair.amountScale
    );
    //
    // Balance info
    $state.balance.currency = params.pair.baseAsset.toUpperCase();
    $state.balance.equivalent.currency = params.pair.quoteAsset.toUpperCase();
    $state.balance.equivalent.value = $state.precise.balance.mul($state.precise.price).toString();
    //
    // Normalize values
    $state.max.amount = normalizeRoundDown($state.max.amount, params.pair.amountScale);

    return $state;
  });

  //
  useEffect(() => {
    state.setPrice(scientificNumbersParser(params.marketClosePrice));
  }, [params.marketClosePrice]);

  const orderbookPressedAmountSignal = useOrderbookPressedAmountSignal();
  useEffect(() => state.set({ amount: orderbookPressedAmountSignal.value }), [orderbookPressedAmountSignal.value]);

  return state;
};

import clsx from 'clsx';
import { useContext, useRef } from 'react';
import { VisuallyHidden, mergeProps, useFocusRing, useHover, useSliderThumb } from 'react-aria';

import { useClientEffect } from '@ping/hooks';
import { clamp, roundPercentage } from '@ping/utils';

import { SliderContext } from '../slider.context';

import style from './style.module.scss';

const ONE_VISIBLE_DECIMAL = 1 as const;
const ZERO = 0 as const;
const ONE_HUNDRED = 100 as const;

type ISliderThumbProps = ICustomizable;

export const SliderThumb = (props: ISliderThumbProps) => {
  const { thumbIndex, state, trackRef, outputProps } = useContext(SliderContext);

  const thumbHandleRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { thumbProps, inputProps, isDragging } = useSliderThumb({ index: thumbIndex, trackRef, inputRef }, state);
  //
  //
  const { focusProps, isFocusVisible } = useFocusRing();
  const { hoverProps: thumbHoverProps, isHovered: isThumbHovered } = useHover({});
  const { hoverProps: thumbHandleHoverProps, isHovered: isThumbHandleHovered } = useHover({});

  /* this will fix the issue with slider still working while user go to iframe CU-#86bx92jyk*/
  useClientEffect(() => {
    document.querySelectorAll('iframe').forEach(iframe => {
      iframe.style.pointerEvents = isDragging ? 'none' : 'auto';
    });
  }, [isDragging]);

  /**
   * Clamps the style left value within the range of 0 and 100, and returns it as a string with a percentage sign.
   *
   */
  const clampStyleLeftValue = () => {
    const leftVal = thumbProps.style.left as string;
    return clamp(ZERO, ONE_HUNDRED, Number(leftVal.replace('%', ''))) + '%';
  };

  return (
    <div
      {...mergeProps(thumbProps, thumbHoverProps)}
      className={clsx(style['slider-thumb'], props.className)}
      data-is-hovered={isThumbHovered}
      data-is-focus-visible={isFocusVisible}
      data-is-dragging={isDragging}
      style={{ ...thumbProps.style, left: clampStyleLeftValue() }}
    >
      <div className={style['slider-thumb__tooltip']} aria-hidden={!isThumbHandleHovered && !isDragging}>
        <output className={style['slider-thumb__output']} {...outputProps}>
          {`${roundPercentage(state.getThumbPercent(thumbIndex) * ONE_HUNDRED, ONE_VISIBLE_DECIMAL)}%`}
        </output>
      </div>

      <span className={style['slider-thumb__handle']} {...thumbHandleHoverProps} ref={thumbHandleRef} />

      <VisuallyHidden>
        <input ref={inputRef} {...mergeProps(inputProps, focusProps)} />
      </VisuallyHidden>
    </div>
  );
};

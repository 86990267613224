import { Store } from '@ping/utils';

type OrderBookPressedPriceStore = {
  value: number;
};

// --------------------------------------------------------------------------------
// ORDER BOOK PRESSED PRICE STORE
// --------------------------------------------------------------------------------
export const useOrderBookPressedPriceStore = new Store<OrderBookPressedPriceStore>({ value: null })
  .withDevTools()
  .build();

// --------------------------------------------------------------------------------
// ORDER BOOK PRESSED PRICE STORE SELECTORS
// --------------------------------------------------------------------------------
export const pressedPriceValueSelector = (state: OrderBookPressedPriceStore) => state.value;

// --------------------------------------------------------------------------------
// ORDER BOOK PRESSED PRICE STORE API
// --------------------------------------------------------------------------------
export const orderBookPressedPriceStore = {
  getValue: () => useOrderBookPressedPriceStore.getState().value,
  setValue: (value: number) => useOrderBookPressedPriceStore.setState({ value }, true),
};

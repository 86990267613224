import { useCallback } from 'react';

import { Store } from '@ping/utils';

interface ISelectedOrderKindSignal {
  value: IOrderKind;
}

// --------------------------------------------------------------------------------
// SELECTED ORDER KIND SIGNAL
// --------------------------------------------------------------------------------
const selectedOrderKindSignal = new Store<ISelectedOrderKindSignal>({ value: 'limit' }).withDevTools().build();

// --------------------------------------------------------------------------------
// SELECTED ORDER KIND HOOK
// --------------------------------------------------------------------------------
export const useSelectedOrderKindSignal = () => {
  const value = selectedOrderKindSignal(state => state.value);
  const set = useCallback((input: IOrderKind) => selectedOrderKindSignal.setState({ value: input }), []);

  return Object.freeze({ value, set });
};

// --------------------------------------------------------------------------------
// SELECTED ORDER KIND NON-REACT
// --------------------------------------------------------------------------------
useSelectedOrderKindSignal.get = () => selectedOrderKindSignal.getState().value;
useSelectedOrderKindSignal.set = (input: IOrderKind) => selectedOrderKindSignal.setState({ value: input });

import { parsePair } from '@ping/helpers';
import { concat, groupBy, mergeWith } from 'rambdax';

/**
 * It groups markets by their base and quote assets names.
 * @param {IMarketModified[]} markets - An array of objects representing markets. Each object should
 * have a property called "market" which represents the name of the market.
 * @returns an array of entries, where each entry consists of a key-value pair. The keys are the
 * lowercase names of the base and quote assets in the markets, and the values are arrays of markets
 * that have the corresponding base or quote asset.
 */
const groupMarketsByAssetName = (markets: IMarketModified[]) => {
  const grouped = mergeWith<Record<string, IMarketModified[]>>(
    concat,
    groupBy(market => parsePair.byMarket(market.market).base.toLowerCase(), markets),
    groupBy(market => parsePair.byMarket(market.market).quote.toLowerCase(), markets)
  );

  return Object.entries(grouped);
};

export const groupMarkets = Object.freeze({
  byAssetName: groupMarketsByAssetName,
});

import Big from 'big.js';
import { isEmpty, isNil } from 'rambdax';

import { type GetQuoteResponse, type MarketInfo } from '@ping/api';

const PERCENTAGE = 100 as const;
const WHOLE = 1 as const;

/**
 * Check if any of the inputs are null or empty.
 *
 * @param {MarketInfo} pair - The market information
 * @param {GetQuoteResponse} order - The quote response
 * @param {'buy' | 'sell'} side - The side of the order
 * @return {boolean} true if any input is null or empty, false otherwise
 */
const isUnknownState = (pair: MarketInfo, order: GetQuoteResponse, side: 'buy' | 'sell') => {
  return isNil(side) || isNil(pair) || isNil(order) || isEmpty(pair) || isEmpty(order);
};

/**
 * Checks if the given side is a buy order.
 *
 * @param {('buy' | 'sell')} side - The side of the order ('buy' or 'sell').
 * @return {boolean} Returns true if the side is 'buy', false otherwise.
 */
const isBuyOrder = (side: 'buy' | 'sell') => {
  return side === 'buy';
};

/**
 * Check if the given side is a sell order.
 *
 * @param {string} side - The side of the order ('buy' or 'sell')
 * @return {boolean} Returns true if the side is 'sell', false otherwise
 */
const isSellOrder = (side: 'buy' | 'sell') => {
  return side === 'sell';
};

/**
 * Calculates the estimated total for an order based on the given market information, order, and side.
 *
 * @param {MarketInfo} pair - The market information for the order pair.
 * @param {GetQuoteResponse} order - The response object for the order quote.
 * @param {'buy' | 'sell'} side - The side of the order ('buy' or 'sell').
 * @return {string} - The value of the estimated total, formatted as a string with the appropriate asset symbol.
 */
const calculateOrderEstimatedTotal = (pair: MarketInfo, order: GetQuoteResponse, side: 'buy' | 'sell') => {
  switch (true) {
    case isUnknownState(pair, order, side): {
      return `---`;
    }

    case isBuyOrder(side): {
      const total = Big(order.fillableAmount);
      const excludedFeeFactor = Big(pair.takerFee).minus(WHOLE).abs();
      const net = total.times(excludedFeeFactor).toFixed(pair.amountScale); //.padEndRemove('0');
      return `~ ${net} ${pair.baseAsset.toUpperCase()}`;
    }

    case isSellOrder(side): {
      const total = Big(order.price).times(order.fillableAmount);
      const excludedFeeFactor = Big(pair.takerFee).minus(WHOLE).abs();
      const net = total.times(excludedFeeFactor).toFixed(pair.priceScale); //.padEndRemove('0');
      return `~ ${net} ${pair.quoteAsset.toUpperCase()}`;
    }
  }
};

/**
 * Calculates the value of the order fee based on the given market information, order, and side.
 *
 * @param {MarketInfo} pair - The market information for the order.
 * @param {GetQuoteResponse} order - The order for which to calculate the fee.
 * @param {'buy' | 'sell'} side - The side of the order ('buy' or 'sell').
 * @return {string} - The value of the order fee, formatted as a string with the appropriate asset symbol.
 */
const calculateOrderFeeValue = (pair: MarketInfo, order: GetQuoteResponse, side: 'buy' | 'sell') => {
  switch (true) {
    case isUnknownState(pair, order, side): {
      return `---`;
    }

    case isBuyOrder(side): {
      const total = Big(order.fillableAmount);
      const applicableFeeFactor = Big(pair.takerFee);
      const fee = total.times(applicableFeeFactor).toFixed(); //.padEndRemove('0');
      return `${fee} ${pair.baseAsset.toUpperCase()}`;
    }

    case isSellOrder(side): {
      const total = Big(order.price).times(order.fillableAmount);
      const applicableFeeFactor = Big(pair.takerFee);
      const fee = total.times(applicableFeeFactor).toFixed(); //.padEndRemove('0');
      return `${fee} ${pair.quoteAsset.toUpperCase()}`;
    }
  }
};

/**
 * Calculates the total fee percentage for a given market pair.
 *
 * @param {MarketInfo} pair - The market information object containing maker and taker fees.
 * @return {string} The total fee percentage calculated as a string.
 */
const calculateOrderTotalFeePercentage = (pair: MarketInfo) => {
  if (isNil(pair) || isEmpty(pair)) {
    return `---`;
  }

  return Big(pair.makerFee).plus(pair.takerFee).times(PERCENTAGE).toFixed();
};

export const calculate = Object.freeze({
  order: {
    estimatedTotal: calculateOrderEstimatedTotal,
    feeValue: calculateOrderFeeValue,
    totalFeePercentage: calculateOrderTotalFeePercentage,
  },
});

import { ReactNode, useEffect } from 'react';
import { ButtonA11Y } from '@ping/uikit';
import { useToggle } from '@ping/hooks';
import { selectedMarketSelector, useMarketStore } from '@ping/stores/market.store';
import CaretDownIcon from '@ping/assets/Icon/caret-down.svg';

import style from './style.module.scss';

interface IMarketPairsDropdownTriggerProps {
  children: ReactNode;
}

export const MarketPairsDropdownTrigger = ({ children }: IMarketPairsDropdownTriggerProps) => {
  const [isVisible, toggleVisibility] = useToggle(false);
  const selectedMarket = useMarketStore(selectedMarketSelector);

  useEffect(() => {
    if (isVisible) {
      toggleVisibility();
    }
  }, [selectedMarket]);

  return (
    <>
      <ButtonA11Y
        className={style['market-pairs-dropdown-trigger']}
        aria-expanded={isVisible}
        onPress={toggleVisibility}
      >
        <CaretDownIcon className={style['market-pairs-dropdown-trigger__icon']} data-expanded={isVisible} />
      </ButtonA11Y>

      <div className={style['market-pairs-dropdown-underlay']} aria-hidden={!isVisible} />
      <div className={style['market-pairs-dropdown-menu']} aria-hidden={!isVisible}>
        {children}
      </div>
    </>
  );
};

import { ChangeEvent, Dispatch, ReactNode, SetStateAction, useCallback } from 'react';
import { clsx } from 'clsx';

import { PingInput, ScrollableTab, Spinner, Table } from '@ping/uikit';
import { useIsUserLoggedIn, useMobile } from '@ping/hooks';
import { parsePair } from '@ping/helpers';
import { marketStore } from '@ping/stores/market.store';
import { getMarketPairsColumns } from '../helpers';

import SearchIcon from '@ping/assets/Icon/search.svg';

import style from './style.module.scss';

interface IMarketPairsProps {
  visiblePairs: [string, IMarketModified[]][];
  handleSearch: (ev: ChangeEvent<HTMLInputElement>) => void;
  showMarket: string;
  coinTabOptions: (
    | {
        value: string;
        view: string;
      }
    | {
        value: 'Fav';
        view: ReactNode;
      }
  )[];
  selectedMarket: string;
  filterPairs: (pairs: any, showMarket: string) => any;
  filterQuery: string;
  setShowMarket: Dispatch<SetStateAction<string>>;
  setFilterQuery?: Dispatch<SetStateAction<string>>;
}

export const MarketPairs = ({
  visiblePairs,
  showMarket,
  setShowMarket,
  coinTabOptions,
  filterPairs,
  handleSearch,
  filterQuery,
  setFilterQuery,
  selectedMarket,
}: IMarketPairsProps) => {
  const isUserLoggedIn = useIsUserLoggedIn();
  const isMobile = useMobile();

  const handleClickRow = useCallback(row => {
    /* reset search input after market change */
    setFilterQuery?.('');
    setShowMarket(row.original.market.toLowerCase().split('/')[0]);
    marketStore.setSelectedMarket(row.original.market);
  }, []);

  return (
    <div className={clsx(style['market-pairs'], visiblePairs.length !== 0 && style['hover-effect'])}>
      {showMarket && visiblePairs.length !== 0 && (
        <>
          <PingInput value={filterQuery} onChange={handleSearch} placeholder='Search' Icon={<SearchIcon />} />
          {!isMobile && (
            <ScrollableTab
              className={style['market-pairs-filter-options']}
              options={coinTabOptions}
              value={showMarket}
              onChange={market => setShowMarket(market as string)}
            />
          )}

          {filterPairs(visiblePairs, showMarket).map(([coinKey, coinData]) => (
            <div key={coinKey} className={style['market-pairs__table-container']}>
              <Table
                data={coinData.filter(coin => coin?.keywords.some(key => key.includes(filterQuery)))}
                columns={getMarketPairsColumns(isUserLoggedIn)}
                isLoading={!visiblePairs.length}
                isMobileStickyColumn={false}
                getRowProps={row => ({
                  className:
                    selectedMarket === parsePair.byInstrument(row.original.instrument).market && style['selected-row'],
                  onClick: () => handleClickRow(row),
                })}
              />
            </div>
          ))}
        </>
      )}

      {visiblePairs.length === 0 && (
        <>
          <div className='text-center my-3'>
            <Spinner size={10} />
          </div>
          <div className={style['market-pairs__table-container']}>
            <Table
              columns={getMarketPairsColumns(isUserLoggedIn)}
              data={[]}
              isLoading={!visiblePairs.length}
              isMobileStickyColumn={false}
              loadingItemsPerPage={18}
            />
          </div>
        </>
      )}
    </div>
  );
};

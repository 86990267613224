import clsx from 'clsx';

import style from './style.module.scss';

interface IFormStructureGroupProps extends ICustomizable {
  hidden?: boolean;
  children: React.ReactNode;
}

export const FormStructureGroup = (props: IFormStructureGroupProps) => (
  <>{!props.hidden && <div className={clsx(style['form-structure-group'], props.className)}>{props.children}</div>}</>
);

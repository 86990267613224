import { QuoteInfo } from '@ping/api';
import { fixNumber, format } from '@ping/utils';
import { FaveStarInput } from '@ping/uikit';
import { handleCoinChange } from '@ping/helpers';

import style from '../MarketPairs/style.module.scss';

export const getMarketPairsColumns = (isLogin: boolean) => {
  const MARKET_PAIRS_COLUMNS = [
    {
      Header: 'Pairs',
      accessor: 'instrument',
      Cell: ({
        row: { original },
      }: {
        row: { original: QuoteInfo & { change: number; changePercent: number; favorites?: boolean; market?: string } };
      }) => (
        <div className={style['pair-column']}>
          <span className={style['record-span']}>{original.market.toUpperCase()}</span>
        </div>
      ),
    },
    {
      Header: 'Last Price',
      accessor: 'close',
      columnTextAlignment: 'center',
      Cell: ({ row: { original } }: { row: { original: QuoteInfo & { change: number; changePercent: number } } }) => (
        <div className={`${style['last-price']} ${style['record-span']}`}>
          <span className={style['price']}>{format.number(original.close)}</span>
          <span className={style['currency']}>{original.instrument.split('/')[1]?.toUpperCase()}</span>
        </div>
      ),
    },
    {
      Header: 'Change',
      accessor: 'changePercent',
      columnTextAlignment: 'end',
      Cell: ({ row: { original } }: { row: { original: QuoteInfo & { change: number; changePercent: number } } }) => (
        <span className={style[handleCoinChange(original.change).className]}>
          {`${fixNumber(original.changePercent)}`}%
        </span>
      ),
    },
  ];

  if (isLogin) {
    MARKET_PAIRS_COLUMNS.unshift({
      Header: '',
      accessor: 'favorites',
      Cell: ({
        row: { original },
      }: {
        row: { original: QuoteInfo & { change: number; changePercent: number; favorites?: boolean; market?: string } };
      }) => <FaveStarInput market={original.market} />,
    } as any);
  }

  return MARKET_PAIRS_COLUMNS;
};

import { SliderTrack } from './SliderTrack';
import { SliderMileStones } from './SliderMileStones';
import { SliderLabel } from './SliderLabel';
import { SliderRoot } from './SliderRoot';
import { SliderThumb } from './SliderThumb';

export const Slider = Object.freeze({
  Root: SliderRoot,
  Label: SliderLabel,
  Thumb: SliderThumb,
  MileStones: SliderMileStones,
  Track: SliderTrack,
});

import { SliderMileStoneItem } from './SliderMileStoneItem';
import style from './style.module.scss';

interface ISliderMileStonesProps extends ICustomizable {
  children: React.ReactNode;
}

export const SliderMileStones = (props: ISliderMileStonesProps) => {
  return <fieldset className={style['slider-milestones']}>{props.children}</fieldset>;
};

SliderMileStones.Item = SliderMileStoneItem;

import { useEffect, useState } from 'react';

const useIsScrolling = () => {
  const [isScrolling, setIsScrolling] = useState(false);

  const handleScroll = (debounceTime = 200) => {
    let timeOut = null;
    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      } else {
        /* no need to set again since it's already true */
        setIsScrolling(true);
      }
      timeOut = setTimeout(() => {
        setIsScrolling(false);
      }, debounceTime);
    };
  };

  const debouncedHandleScroll = handleScroll();

  useEffect(() => {
    document.addEventListener('scroll', debouncedHandleScroll);

    return () => {
      document.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, []);

  return isScrolling;
};

export default useIsScrolling;

import { RefObject } from 'react';
import { Row } from 'react-table';
import { useVirtual } from 'react-virtual';

interface IUseRowVirtualizer {
  rows: Row<any>[];
  ref: RefObject<HTMLDivElement>;
}

const useRowVirtualizer = ({ rows, ref }: IUseRowVirtualizer) => {
  const rowVirtualizer = useVirtual({
    parentRef: ref,
    size: rows.length,
    overscan: 5,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  // By using this padding, users will be able to see how many items are left to scroll
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  return { virtualRows, totalSize, paddingTop, paddingBottom };
};

export default useRowVirtualizer;

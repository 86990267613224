import { WEBSOCKET_URL } from '@ping/configs';
import { useWebSocketMarket } from './useWebSocketMarket';

export type ITrades = {
  amount: number;
  executionPrice: number;
  instrument: string;
  side: 'Buy' | 'Sell'; // Do not replace with IOrderSide (the first letters are capitalized here).
  tradeId: number;
  tradeTime: string;
};

const handleTradeData = (data: ITrades[], interval, callBackState) => {
  callBackState(data);
  clearInterval(interval);
};

export const useTradeHistoryWS = () => useWebSocketMarket<ITrades[]>(WEBSOCKET_URL, 'Trades', handleTradeData);

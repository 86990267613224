import { Big } from 'big.js';
import { isNil } from 'rambdax';

import { type OrderInfoResponse } from '@ping/api';
import { countNumberDecimals } from '@ping/utils/number-helper.util';
import { type IOrderDetail } from '@ping/websockets';

type AggregatedRecord = { price: Big; amount: Big; total: Big; precisePrice: number };

/**
 * Shifts the order price based on the given margin (aggregation value).
 *
 * @param {number} price - the original price
 * @param {number} margin - the aggregation value
 * @param {'ask' | 'bid' | 'sell' | 'buy' | 'Sell' | 'Buy'} side - the side of the order
 */
export const shiftOrderPrice = (
  price: number,
  margin: number,
  side: 'ask' | 'bid' | 'sell' | 'buy' | 'Sell' | 'Buy'
) => {
  return Big(price)
    .div(margin)
    .round(0, ['ask', 'sell', 'Sell'].includes(side) ? Big.roundUp : Big.roundDown)
    .times(margin)
    .toFixed(countNumberDecimals(margin));
};

/**
 * Groups and aggregates orderbook records based on margin and side.
 *
 * @param {number} margin - the margin value for grouping records
 * @param {'ask' | 'bid'} side - the side of the orderbook ('ask' or 'bid')
 * @return {(shiftedRecords: AggregatedRecord[], record: IOrderDetail) => AggregatedRecord[]} a function that takes an array of aggregated records and an order detail record, and returns an array of aggregated records
 */
const groupAndAggregateOrderbookRecords = (margin: number, side: 'ask' | 'bid') => {
  const groupToAggregatedIndex = new Map<string, number>();

  return (aggregated: AggregatedRecord[], record: IOrderDetail) => {
    const group = shiftOrderPrice(record.price, margin, side);
    const index = groupToAggregatedIndex.get(group) ?? aggregated.length;

    if (isNil(aggregated[index])) {
      groupToAggregatedIndex.set(group, aggregated.length);
      aggregated[index] = {
        price: Big(group),
        amount: Big(0),
        total: Big(0),
        precisePrice: record.price,
      };
    }

    aggregated[index].amount = aggregated[index].amount.plus(record.amount);
    aggregated[index].total = aggregated[index].total.plus(Big(record.amount).times(record.price));
    aggregated[index].precisePrice = record.price;

    return aggregated;
  };
};

/**
 * Maps the orderbook record Big numbers to their numeric values.
 *
 * @param {AggregatedRecord} record - the original aggregated record
 */
const mapOrderbookRecordNumbers = (record: AggregatedRecord) => {
  return {
    ...record,
    price: record.price.toNumber(),
    amount: record.amount.toNumber(),
    total: record.total.toNumber(),
  };
};

/**
 * Sorts orderbook records by price in descending order.
 *
 * @param {AggregatedRecord} x - the first record to compare
 * @param {AggregatedRecord} y - the second record to compare
 */
const sortOrderbookRecordsByPriceDescending = (x: AggregatedRecord, y: AggregatedRecord) => {
  return y.price.minus(x.price).toNumber();
};

/**
 * Aggregates orderbook records based on the given margin and side.
 *
 * @param {IOrderDetail[]} records - array of orderbook records
 * @param {number} margin - margin for aggregation
 * @param {'ask' | 'bid'} side - side of the orderbook
 */
export const aggregateOrderbookRecords = (records: IOrderDetail[] = [], margin: number, side: 'ask' | 'bid') => {
  return records
    .reduce(groupAndAggregateOrderbookRecords(margin, side), [])
    .sort(sortOrderbookRecordsByPriceDescending)
    .map(mapOrderbookRecordNumbers);
};

/**
 * Shifts and distinct user open order prices based on the provided margin (aggregation value).
 *
 * @param {OrderInfoResponse[]} records - the list of order information responses
 * @param {number} margin - the aggregation value
 */
export const shiftUserOpenOrderPrices = (records: OrderInfoResponse[] = [], margin: number) => {
  const result = { sell: new Set<string>(), buy: new Set<string>() };

  for (const record of records) {
    if (record.status !== 'Working') {
      continue;
    }

    const shiftedPrice = shiftOrderPrice(record.price, margin, record.side);
    result[record.side.toLocaleLowerCase()].add(Number(shiftedPrice));
  }

  return Object.freeze(result);
};

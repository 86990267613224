/**
 * It checks if a given value is a string.
 * @param {unknown} value
 */
const isString = (value: unknown) => typeof value === 'string';

/**
 * It checks if all form fields are valid by verifying if there are no error in the provided object.
 * @param error - The `error` parameter is an object that contains error messages for form fields.
 * The keys of the object represent the names of the form fields, and the values represent the error
 * messages associated with those fields. If a field has no error message, its value in the object
 * must be `undefined`.
 */
export const isFormFieldsValid = (error: Record<string, string | undefined>) => {
  return Object.values(error).filter(isString).length === 0;
};

import { useState } from 'react';
import { t } from '@ping/helpers';
import { getPairs, useIsUserLoggedIn, useMobile, useTabletDevice } from '@ping/hooks';
import { Modal } from '@ping/uikit';

import { MarketOrderPair } from '../../MarketOrderPair';
import { SelectedMarketPair } from '../../SelectedMarketPair';
import { OrderForm } from '../OrderForm';
import { MarketPairs } from '../../MarketOrderPair/MarketPairs';
import { MarketPairsModal } from '../../MarketOrderPair/MarketPairsModal';
import { useUpdateMarketPairs } from '../../MarketOrderPair/helpers';
import FavStar from '@ping/assets/Icon/favIcon.svg';

import type { parsePair } from '@ping/helpers';

import style from './style.module.scss';
interface IOrderFormModalTriggersProps {
  pair: ReturnType<typeof parsePair.byInstrument>;
  entrance?: 'start' | 'bottom';
}

export const OrderFormModalTriggers = (props: IOrderFormModalTriggersProps) => {
  props = { entrance: 'bottom', ...props };
  const [isMarketPairsModalOpen, setIsMarketPairsModalOpen] = useState(false);
  const isMobile = useMobile();
  const isTablet = useTabletDevice();
  const [activeSide, setActiveSide] = useState<IOrderSide>();

  const isUserLoggedIn = useIsUserLoggedIn();
  const pairs = getPairs();

  const {
    showMarket,
    setShowMarket,
    selectedMarket,
    filterQuery,
    filterPairs,
    visiblePairs,
    coinTabOptions,
    handleSearch,
  } = useUpdateMarketPairs({
    pairs,
    icon: <FavStar />,
    showFav: isUserLoggedIn,
    isShowMarketAll: !isMobile,
  });

  return (
    <>
      <Modal.Root>
        <div className={style['order-form-modal-triggers']}>
          <Modal.Trigger
            className={style['order-form-modal-triggers__button']}
            data-side='buy'
            onPressStart={() => setActiveSide('buy')}
            isDisabled={!isUserLoggedIn}
          >
            {isUserLoggedIn ? `${t('Buy')} ${props.pair.base}` : t('Buy')}
          </Modal.Trigger>

          <Modal.Trigger
            className={style['order-form-modal-triggers__button']}
            data-side='sell'
            onPressStart={() => setActiveSide('sell')}
            isDisabled={!isUserLoggedIn}
          >
            {isUserLoggedIn ? `${t('Sell')} ${props.pair.base}` : t('Sell')}
          </Modal.Trigger>
        </div>

        <Modal.Portal
          className={style['order-form-modal-portal']}
          entrance={props.entrance}
          invisible={isMarketPairsModalOpen}
        >
          <Modal.Header className={style['order-form-modal-portal__header']}>
            {!isTablet && (
              <Modal.Title headerTag='div'>
                <SelectedMarketPair />
              </Modal.Title>
            )}

            <Modal.CloseButton className={style['order-form-modal-portal__close-button']} />
          </Modal.Header>

          <Modal.Content className={style['order-form-modal-portal__content']}>
            {isTablet && (
              <MarketOrderPair
                onMarketPairsModalOpenChange={setIsMarketPairsModalOpen}
                onSetShowMarket={setShowMarket}
              />
            )}
            <OrderForm pair={props.pair} activeSide={activeSide} />
          </Modal.Content>
        </Modal.Portal>
      </Modal.Root>
      {isTablet && (
        <MarketPairsModal onOpenChange={setIsMarketPairsModalOpen}>
          <MarketPairs
            visiblePairs={visiblePairs}
            filterQuery={filterQuery}
            handleSearch={handleSearch}
            filterPairs={filterPairs}
            showMarket={showMarket}
            setShowMarket={setShowMarket}
            coinTabOptions={coinTabOptions}
            selectedMarket={selectedMarket}
          />
        </MarketPairsModal>
      )}
    </>
  );
};

import clsx from 'clsx';
import { forwardRef, useContext, useLayoutEffect, useMemo } from 'react';
import { DismissButton, FocusScope, OverlayContainer, mergeProps, useDialog, useModal, useOverlay } from 'react-aria';

import { PopoverContext } from '../popover.context';
import style from './style.module.scss';

import type IReactAria from 'react-aria';

interface IPopoverContentProps extends IReactAria.AriaOverlayProps {
  className?: string;
  style?: any;
  boundaryRef: React.MutableRefObject<HTMLElement>;
  children: React.ReactNode;
  placement: 'top' | 'bottom' | 'left' | 'right' | 'center';
}

interface IPopoverPanelProps {
  className?: string;
  children: React.ReactNode;
  'data-part'?: string;
}

const PopoverContent = forwardRef((props: IPopoverContentProps, ref: React.RefObject<HTMLDivElement>) => {
  const { children, className, isOpen, onClose, boundaryRef, style: positionStyle, placement, ...restProps } = props;

  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const { overlayProps } = useOverlay({ onClose, isOpen, isDismissable: true }, ref);

  // Hide content outside the modal from screen readers.
  const { modalProps } = useModal();

  // Get props for the dialog and its title
  const { dialogProps } = useDialog({}, ref);

  // Set the popover content's inline size to the inline size of its boundary element
  // Update the popover content's position on page resizing
  const resizeObserver = useMemo(() => {
    return new ResizeObserver(entries => {
      for (const entry of entries) {
        const rect = entry.target.getBoundingClientRect();
        if (!ref.current) {
          continue;
        }

        ref.current.style.inlineSize = rect.width + 'px';
        ref.current.style.left = rect.left + 'px';
      }
    });
  }, [placement]);

  useLayoutEffect(() => {
    if (boundaryRef?.current) {
      resizeObserver.observe(boundaryRef.current);
    }

    return () => {
      if (!boundaryRef?.current) return;

      resizeObserver.unobserve(boundaryRef.current);
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <FocusScope restoreFocus>
      {/* NOTE: "style/positionStyle" comes from "react-aria" to adjust position of popover content */}
      <div
        className={clsx(style['popover-content'], className)}
        data-placement={placement}
        data-part={props['data-part']}
        style={positionStyle}
        ref={ref}
        {...mergeProps(overlayProps, dialogProps, restProps, modalProps)}
      >
        {children}
        <DismissButton onDismiss={onClose} />
      </div>
    </FocusScope>
  );
});

const PopoverPanel = ({ children, className, 'data-part': part }: IPopoverPanelProps) => {
  const { panelSubComponentProps } = useContext(PopoverContext);
  const { state, overlayRef, boundaryRef, overlayProps, positionProps, placement } = panelSubComponentProps;

  return (
    <>
      {state.isOpen && (
        <OverlayContainer>
          <PopoverContent
            {...mergeProps(overlayProps, positionProps)}
            ref={overlayRef}
            boundaryRef={boundaryRef}
            isOpen={state.isOpen}
            onClose={state.close}
            className={className}
            placement={placement}
            data-part={part}
          >
            {children}
          </PopoverContent>
        </OverlayContainer>
      )}
    </>
  );
};

export default PopoverPanel;

import { ReactNode } from 'react';
import { marketsKeys } from '../helpers';

export const coinTabOptions = (pairs: [string, IMarketModified[]][], icon: ReactNode, showFav: boolean) => {
  return [
    { value: marketsKeys.ALL_MARKETS, view: marketsKeys.ALL_MARKETS },
    ...(showFav ? [{ value: marketsKeys.FAVORITE_MARKETS, view: icon }] : []),
    ...pairs.map(([coinKey]) => ({ value: coinKey, view: coinKey.toUpperCase() })),
  ];
};

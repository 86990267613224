import clsx from 'clsx';

import style from './style.module.scss';

interface IFormStructureStatsCellProps extends ICustomizable {
  children: React.ReactNode;
}

export const FormStructureStatsCell = (props: IFormStructureStatsCellProps) => (
  <span className={clsx(style['form-structure-stats-cell'], props.className)}>{props.children}</span>
);

import { Big } from 'big.js';

import type { BigSource } from 'big.js';

/**
 * It takes in a lower and upper bound, and a value, and returns the value clamped within the bounds.
 * @param {BigSource} [lower=0] - The optional lower bound of the range to clamp the value to.
 * defaults to 0 if not provided.
 * @param {BigSource} upper - The `upper` parameter is a number or string that represents the upper
 * bound of the range.
 * @param {BigSource} value - The value that needs to be clamped between the lower and upper bounds.
 * @returns The `clamp` function returns the `value` parameter if it is within the range defined by
 * `lower` and `upper`. If `value` is less than `lower`, then `lower` is returned. If `value` is
 * greater than `upper`, then `upper` is returned.
 */
export const clamp = (lower: BigSource = 0, upper: BigSource, value: BigSource) => {
  let result = value;
  const preciseLowe = Big(lower);
  const preciseUpper = Big(upper);
  const preciseValue = Big(value);

  if (preciseValue.lt(preciseLowe)) {
    result = lower;
  } else if (preciseValue.gt(preciseUpper)) {
    result = upper;
  }

  return result;
};

import { useCallback } from 'react';

import { Store } from '@ping/utils';

const PRISTINE = '' as const;

interface IOrderbookPressedAmountSignal {
  value: string;
}

// --------------------------------------------------------------------------------
// ORDERBOOK PRESSED AMOUNT SIGNAL
// --------------------------------------------------------------------------------
const orderbookPressedAmountSignal = new Store<IOrderbookPressedAmountSignal>({ value: PRISTINE })
  .withDevTools()
  .build();

// --------------------------------------------------------------------------------
// ORDERBOOK PRESSED AMOUNT HOOK
// --------------------------------------------------------------------------------
export const useOrderbookPressedAmountSignal = () => {
  const value = orderbookPressedAmountSignal(state => state.value);
  // const reset = useCallback(() => orderbookPressedAmountSignal.setState({ value: PRISTINE }), []);
  const set = useCallback(
    (input: number | string) => orderbookPressedAmountSignal.setState({ value: String(input) }),
    []
  );

  return Object.freeze({ value, set });
};

// --------------------------------------------------------------------------------
// ORDERBOOK PRESSED AMOUNT NON-REACT
// --------------------------------------------------------------------------------
useOrderbookPressedAmountSignal.get = () => orderbookPressedAmountSignal.getState().value;
useOrderbookPressedAmountSignal.reset = () => orderbookPressedAmountSignal.setState({ value: PRISTINE });
useOrderbookPressedAmountSignal.set = (input: number | string) =>
  orderbookPressedAmountSignal.setState({ value: String(input) });

import { isEmpty } from 'rambdax';

import { scientificNumbersParser } from '@ping/helpers';
import { countNumberDecimals, isFloat } from '@ping/utils';

import style from './style.module.scss';

const ZERO = 0 as const;
const DECIMAL_POINT = '.' as const;
const EMPTY = '' as const;

interface INumberWithExtraZerosProps {
  value: number;
  maxDecimal?: number;
  suffix?: string;
}

export const NumberWithExtraZeros = ({ value, maxDecimal = ZERO, suffix = '' }: INumberWithExtraZerosProps) => {
  const paddingZeros = '0'.repeat(Math.max(maxDecimal - countNumberDecimals(value), ZERO));

  return (
    <>
      <span className='real-number'>{scientificNumbersParser(value)}</span>
      {!isEmpty(paddingZeros) && (
        <span className={style['extra-zeros']}>
          {!isFloat(value) ? DECIMAL_POINT : EMPTY}
          {paddingZeros}
        </span>
      )}
      {suffix}
    </>
  );
};

import { ReactNode, useEffect } from 'react';

import { useRemoteModal } from '@ping/hooks';
import { Modal } from '@ping/uikit';
import { t } from '@ping/helpers';
import { modalKeys } from '@ping/constants/modal-keys.constant';
import { selectedMarketSelector, useMarketStore } from '@ping/stores/market.store';

import style from './style.module.scss';

interface IMarketPairsModalProps {
  children: ReactNode;
  onOpenChange?: (isOpen: boolean) => void;
}

export const MarketPairsModal = ({ children, onOpenChange }: IMarketPairsModalProps) => {
  const modal = useRemoteModal(modalKeys.PAIR_SELECTION, { onOpenChange });

  const selectedMarket = useMarketStore(selectedMarketSelector);

  useEffect(() => {
    if (modal.state.isOpen) {
      modal.state.close();
    }
  }, [selectedMarket]);

  return (
    <Modal.Root {...modal.props}>
      <Modal.Portal className={style['market-pairs-modal-portal']} entrance='start'>
        <Modal.Header className={style['market-pairs-modal-portal__header']}>
          <Modal.Title>{t('Select a pair')}</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>

        <Modal.Content className={style['market-pairs-modal-portal__content']}>{children}</Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};

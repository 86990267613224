import { useMemo } from 'react';

import { GetStartedButton } from '@ping/components/Header';
import { parsePair, t } from '@ping/helpers';
import { useIsUserLoggedIn } from '@ping/hooks';
import { useDesktopDevice, useMobile, useTabletDevice } from '@ping/hooks/useBreakpoint';
import { selectedMarketInstrumentSelector, useMarketStore } from '@ping/stores/market.store';
import { isUserActivityStatusBannedSelector, useUserInformationStore } from '@ping/stores/userInformation.store';
import { WEBSITE_BASE_URL } from '@ping/configs';
import { OrderForm } from './OrderForm';
import { OrderFormModalTriggers } from './OrderFormModalTriggers';

import style from './style.module.scss';

const SignInOverlay = () => (
  <div className={style['overlay']} data-testid='sign-in-overlay'>
    <div className={style['overlay__title']}>{t('Start trading today')}</div>
    <p className={style['overlay__description']}>
      {t('By creating an account you agree to our ', 'Data protection guidelines')}
      &nbsp;
      <a href={`${WEBSITE_BASE_URL}terms-of-service`} target='_blank' className={style['overlay__link']}>
        {t('Terms and Conditions')}
      </a>
      &nbsp;
      {t('and')}
      &nbsp;
      <a href={`${WEBSITE_BASE_URL}privacy-policy`} target='_blank' className={style['overlay__link']}>
        {t('Data protection guidelines')}
      </a>
    </p>
    <GetStartedButton />
  </div>
);

const BannedOverlay = () => (
  <div className={style['overlay']} data-testid='banned-in-overlay'>
    <p className={style['overlay__description']}>{t('Sorry we cannot provide a service to you at this moment.')}</p>
  </div>
);

export const MarketOrderForm = () => {
  const instrument = useMarketStore(selectedMarketInstrumentSelector);
  const isUserLoggedIn = useIsUserLoggedIn();
  const isUserActivityStatusBanned = useUserInformationStore(isUserActivityStatusBannedSelector);
  const pair = useMemo(() => parsePair.byInstrument(instrument), [instrument]);
  const isDesktop = useDesktopDevice();
  const isTablet = useTabletDevice();
  const isMobile = useMobile();

  return (
    <div className={style['market-order-form']}>
      {!isUserLoggedIn && isDesktop && <SignInOverlay />}
      {isUserActivityStatusBanned && <BannedOverlay />}
      {isDesktop && <OrderForm pair={pair} />}
      {isTablet && <OrderFormModalTriggers pair={pair} entrance='start' />}
      {isMobile && <OrderFormModalTriggers pair={pair} />}
    </div>
  );
};

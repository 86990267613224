import { useContext } from 'react';
import clsx from 'clsx';

import { SliderContext } from '../slider.context';

import style from './style.module.scss';

interface ISliderLabelProps extends ICustomizable {
  children: React.ReactNode;
}

export const SliderLabel = (props: ISliderLabelProps) => {
  const { labelProps } = useContext(SliderContext);

  return (
    <label {...labelProps} className={clsx(style['slider-label'], props.className)}>
      {props.children}
    </label>
  );
};

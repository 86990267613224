import { clsx } from 'clsx';
import { useContext } from 'react';
import { useButton } from 'react-aria';

import { PopoverContext } from '../popover.context';
import style from './style.module.scss';

import type { AriaButtonProps } from '@react-types/button';

interface IButtonProps extends AriaButtonProps<'button'> {
  className?: string;
  triggerRef: React.RefObject<HTMLButtonElement>;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
}

const PopoverTriggerButton = ({ triggerRef, children, className, onClick, ...props }: IButtonProps) => {
  const { buttonProps } = useButton(props, triggerRef);

  return (
    <button className={className} type='button' {...buttonProps} ref={triggerRef} onClick={onClick}>
      {children}
    </button>
  );
};

interface IPopoverTriggerProps extends AriaButtonProps<'button'> {
  className?: string;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
}

const PopoverTrigger = ({ children, className, ...directProps }: IPopoverTriggerProps) => {
  const { triggerSubComponentProps } = useContext(PopoverContext);
  const { triggerProps, triggerRef, placement } = triggerSubComponentProps;

  return (
    <PopoverTriggerButton
      {...triggerProps}
      {...directProps}
      className={clsx(style['popover-trigger'], className)}
      data-placement={placement}
      triggerRef={triggerRef}
    >
      {children}
    </PopoverTriggerButton>
  );
};

export default PopoverTrigger;

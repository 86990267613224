import { isNil } from 'rambdax';
import { useEffect, useState } from 'react';

import { getGetPortfolioQueryKey, getMyTradeQueryKey, getOrderHistoryTradeQueryKey } from '@ping/api';
import { t } from '@ping/helpers';
import { useIsUserLoggedIn } from '@ping/hooks';
import { queryClient } from '@ping/providers/ReactQueryProvider';
import { Tabs } from '@ping/uikit';
import { NotificationEvents, useNotificationWS } from '@ping/websockets';

import AvailableBalance from './AvailableBalance';
import BalanceTable from './BalanceTable';
import OpenOrdersTable from './OpenOrdersTable';
import OrderHistoryTable from './OrderHistoryTable';
import style from './style.module.scss';

import type ReactQuery from 'react-query';

// TODO: check api call orders first then make it global
const FIVE_MINUTES = 5 * 60 * 1000;

/**
 * It invalidates React Query queries based on changes in the order status.
 * @param {ReactQuery.QueryKey[]} queryKeys - The `queryKeys` parameter is an array of ReactQuery query
 * keys. These query keys are used to identify the queries that need to be invalidated when the order
 * status changes.
 */
const useInvalidationOnOrderStatusChanged = (...queryKeys: ReactQuery.QueryKey[]) => {
  const { notificationData: order, notificationCallBack } = useNotificationWS();
  notificationCallBack?.(NotificationEvents.OnOrderStatusChanged);

  useEffect(() => {
    if (isNil(order)) {
      return;
    }

    queryKeys.forEach(key => queryClient.invalidateQueries(key));
  }, [order?.status]);
};

export const TradingRecords = () => {
  const isUserLoggedIn = useIsUserLoggedIn();
  const [selectedTab, setSelectedTab] = useState<IExchangeOrdersTabKey>('openOrders');

  useInvalidationOnOrderStatusChanged(getMyTradeQueryKey(), getOrderHistoryTradeQueryKey(), getGetPortfolioQueryKey());

  //
  // TODO: simplify nested api calls
  // TODO: extract nested api calls into here or setup a caching mechanism
  return (
    <div className={style['trading-records']}>
      <AvailableBalance className={style['trading-records__available-balance']} />
      <Tabs.Root
        className={style['trading-records__tabs']}
        variant='tertiary'
        defaultSelectedKey='openOrders'
        isDisabled={!isUserLoggedIn}
        onSelectionChange={key => setSelectedTab(key as IExchangeOrdersTabKey)}
      >
        <Tabs.Item key='openOrders' title={t('Open Orders')}>
          <OpenOrdersTable
            selectedTab={selectedTab}
            isUserLoggedIn={isUserLoggedIn}
            className={style['table-container']}
          />
        </Tabs.Item>
        <Tabs.Item key='orderHistory' title={t('Order History')}>
          <OrderHistoryTable
            selectedTab={selectedTab}
            isUserLoggedIn={isUserLoggedIn}
            className={style['table-container']}
          />
        </Tabs.Item>
        <Tabs.Item key='balance' title={t('Balance')}>
          <BalanceTable
            selectedTab={selectedTab}
            isUserLoggedIn={isUserLoggedIn}
            staleTime={FIVE_MINUTES}
            className={style['table-container']}
          />
        </Tabs.Item>
      </Tabs.Root>
    </div>
  );
};

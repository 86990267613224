import { Big } from 'big.js';
import { normalize, normalizeRoundDown } from '@ping/utils';
import { useEffect } from 'react';

import { subscribeOrderBookPressedPriceValue } from '@ping/stores/orderBookPressedPrice.effects';
import { useOrderFormCommonState, validate } from '../helpers';

import type { IOrderFormStateParams } from '../helpers';

const ZERO = 0 as const;

export const useStopLimitSellFormState = (params: IOrderFormStateParams) => {
  const state = useOrderFormCommonState('stop-limit buy', params, $state => {
    //
    // Precise values
    $state.precise.activationPrice = Big($state.activationPrice || ZERO);
    $state.precise.limitPrice = Big($state.limitPrice || ZERO);
    $state.precise.amount = Big($state.amount || ZERO);
    $state.precise.marketClosePrice = Big($state.marketClosePrice || ZERO);
    $state.precise.balance = Big($state.balance.value || ZERO);
    $state.precise.total = $state.precise.amount.mul($state.precise.limitPrice);
    //
    // Max values
    $state.max.activationPrice = Big(Number.MAX_SAFE_INTEGER);
    $state.max.limitPrice = Big(Number.MAX_SAFE_INTEGER);
    $state.max.amount = $state.precise.balance;
    //
    // Form status
    $state.isMaxButtonVisible =
      !$state.precise.balance.eq(ZERO) &&
      !$state.precise.amount.eq(normalize($state.max.amount, params.pair.amountScale));
    $state.isAmountSliderDisabled = $state.precise.balance.eq(ZERO);
    //
    // Error messages
    $state.error.activationPrice = validate.price(
      params.pair.minPrice,
      $state.max.activationPrice,
      $state.precise.activationPrice,
      params.pair.priceScale
    );
    $state.error.limitPrice = validate.price(
      params.pair.minPrice,
      $state.max.limitPrice,
      $state.precise.limitPrice,
      params.pair.priceScale
    );
    $state.error.amount = validate.amount(
      params.pair.minAmount,
      $state.max.amount,
      $state.precise.amount,
      params.pair.amountScale
    );
    //
    // Balance info
    $state.balance.currency = params.pair.baseAsset.toUpperCase();
    $state.balance.equivalent.currency = params.pair.quoteAsset.toUpperCase();
    $state.balance.equivalent.value = $state.precise.balance.mul($state.precise.marketClosePrice).toString();
    //
    // Normalize values
    $state.max.activationPrice = normalize($state.max.activationPrice, params.pair.priceScale);
    $state.max.limitPrice = normalize($state.max.limitPrice, params.pair.priceScale);
    $state.max.amount = normalizeRoundDown($state.max.amount, params.pair.amountScale);

    return $state;
  });

  //
  // Prefilled the limit-price with pressed value at the orderbook
  useEffect(() => subscribeOrderBookPressedPriceValue(state.setLimitPrice).unsubscribe, []);

  return state;
};

import clsx from 'clsx';
import { type } from 'rambdax';
import { useContext } from 'react';

import { TimerContext } from '../timer.context';

import style from './style.module.scss';

interface ITimerDescriptionProps extends ICustomizable {
  children: ((remaining: number) => React.ReactNode) | React.ReactNode;
}

export const TimerDescription = (props: ITimerDescriptionProps) => {
  const ctx = useContext(TimerContext);

  return (
    <p className={clsx(style['timer-description'], props.className)}>
      {type(props.children) === 'Function' ? (props.children as any)(ctx.remaining) : props.children}
    </p>
  );
};

import { getOrderHistoryTradeQueryKey, orderHistoryTrade } from '@ping/api';
import { getNextPageParam } from '@ping/helpers';
import { Table } from '@ping/uikit';
import { useInfiniteLoading } from '@ping/uikit/Table/useInfiniteLoading';
import { useInfiniteQuery } from 'react-query';
import NoDataIcon from '@ping/assets/Icon/no-data-found.svg';

import getOrdersColumnsList from '@ping/helpers/get-orders-columns-list';

const OrderHistoryTable = ({ selectedTab, isUserLoggedIn, className = '' }: IExchangeOrdersTabProps) => {
  const {
    data: orderHistory,
    isLoading: isOrderHistoryLoading,
    hasNextPage: orderHistoryHasNextPage,
    fetchNextPage: orderHistoryFetchNextPage,
    isFetchingNextPage: isOrderHistoryFetchingNextPage,
  } = useInfiniteQuery(
    getOrderHistoryTradeQueryKey(),
    ({ pageParam }) => orderHistoryTrade({ Page: pageParam, OrderBy: 'UpdatedAt desc' }),
    {
      enabled: isUserLoggedIn ? selectedTab === 'orderHistory' : false,
      getNextPageParam,
    }
  );
  const { observerElement: orderHistoryObserverElement } = useInfiniteLoading({
    hasNextPage: orderHistoryHasNextPage,
    fetchNextPage: orderHistoryFetchNextPage,
  });
  const ordersColumnsList = getOrdersColumnsList(null, null, true);

  if (!isOrderHistoryLoading && (!orderHistory?.pages || orderHistory?.pages?.length === 0)) {
    return (
      <div className='trading-records__no-data-icon'>
        <NoDataIcon />
      </div>
    );
  }

  return (
    <div className={className}>
      <Table
        data={orderHistory?.pages || []}
        columns={ordersColumnsList}
        isLoading={isOrderHistoryLoading}
        ref={orderHistoryObserverElement}
        isFetchingNextPage={isOrderHistoryFetchingNextPage}
        hasInfiniteLoading
        initialState={{ sortBy: [{ id: 'updatedAt', desc: true }] }}
      />
    </div>
  );
};

export default OrderHistoryTable;

import { isNil } from '@ping/utils';
import { selectedMarketInstrumentSelector, useMarketStore } from '@ping/stores/market.store';

import { useMarketPair, usePortfolio } from '../helpers';
import { OrderFormStructure } from '../OrderFormStructure';
import { useLimitSellFormState } from './limit-order-sell-form-state.hook';

import type { IMarketDataType } from '@ping/websockets';

const ZERO = 0 as const;

interface ILimitOrderSellFormProps {
  market: IMarketDataType;
  isDisabled?: boolean;
}

export const LimitOrderSellForm = (props: ILimitOrderSellFormProps) => {
  const instrument = useMarketStore(selectedMarketInstrumentSelector);
  const pair = useMarketPair(instrument);
  const { portfolio, isPortfolioFetched } = usePortfolio();
  const balance = isPortfolioFetched ? portfolio.get(pair.baseAsset)?.availableAmount : ZERO;

  const sellState = useLimitSellFormState({
    pair,
    balance,
    marketClosePrice: props.market?.close ?? ZERO,
  });

  return (
    <OrderFormStructure
      orderKind='limit'
      orderSide='sell'
      instrument={instrument}
      state={sellState}
      isDisabled={props.isDisabled || isNil(balance) || balance === ZERO}
    />
  );
};

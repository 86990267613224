import Big, { type BigSource } from 'big.js';
import clsx from 'clsx';
import { useRef } from 'react';
import { useNumberFormatter, useSlider } from 'react-aria';
import { useSliderState } from 'react-stately';

import { clamp, domEvent, normalize } from '@ping/utils';

import { SliderContext } from '../slider.context';

import style from './style.module.scss';

import type IReactAria from 'react-aria';

interface ISliderRootProps extends IReactAria.AriaSliderProps, ICustomizable {
  formatOptions?: Intl.NumberFormatOptions;
  onChangeValue?: (value: string) => void;
  scale: number;
  preciseMinValue?: Big;
  preciseMaxValue?: Big;
  preciseStep?: Big;
  children: React.ReactNode;
}

const ZERO = 0 as const;
const ONE_HUNDRED = 100 as const;

export const SliderRoot = (props: ISliderRootProps) => {
  //
  // slider state
  const numberFormatter = useNumberFormatter(props.formatOptions);
  const state = useSliderState({
    ...props,
    step: props.preciseStep?.toNumber() || props.step,
    maxValue: props.preciseMaxValue?.toNumber() || props.maxValue || ONE_HUNDRED,
    minValue: props.preciseMinValue?.toNumber() || props.minValue || ZERO,
    numberFormatter,
    onChange: normalizeAndEmitChange(props),
  });
  //
  // slider props
  const trackRef = useRef<HTMLDivElement>(null);
  const { groupProps, trackProps, labelProps, outputProps } = useSlider(props, state, trackRef);

  return (
    <div
      {...groupProps}
      className={clsx(style['slider-root'], props.className)}
      data-is-disabled={state.isDisabled}
      onChange={event => {
        domEvent.cancel(event);

        const { value: percent } = event.target as EventTarget & { value: string };
        const portion = Big(percent)
          .div(ONE_HUNDRED)
          .times(props.preciseMaxValue ?? props.maxValue ?? ONE_HUNDRED);

        normalizeAndEmitChange(props)(portion);
      }}
    >
      <SliderContext.Provider value={{ thumbIndex: ZERO, state, labelProps, outputProps, trackRef, trackProps }}>
        {props.children}
      </SliderContext.Provider>
    </div>
  );
};

const normalizeAndEmitChange = (props: ISliderRootProps) => (input: BigSource | BigSource[]) => {
  const [value] = [input].flat();
  const normalized = normalize(value, props.scale);
  const clamped = clamp(
    props.preciseMinValue ?? props.minValue ?? ZERO,
    props.preciseMaxValue ?? props.maxValue ?? ONE_HUNDRED,
    normalized
  );

  props.onChangeValue?.(clamped.toString());
};

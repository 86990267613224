import { memo } from 'react';
import dynamic from 'next/dynamic';

import style from './style.module.scss';

const TradingViewChart = dynamic(() => import('./TradingViewChart'), { ssr: false });

const TradingChart = () => (
  <div className={style['trading-chart']}>
    <TradingViewChart />
  </div>
);

export const TradingChartMemo = memo(TradingChart);
